#etf-subnav.subnav-banner{
  border-bottom: 3px solid $color-white;
  text-align: center;
  > .etf-container
  {
    > ul
    {
      display: inline-block;
      text-align: center;
      > li
      {
        font-weight: bold;
      }
    }
  }
}

#etf-subnav > .etf-container
{
	@include container-base(0.125);
	@include link-color($color-white, $color-turquoise);
	
	font-size: 1.167em;
	
	> ul
	{
		@include menu-reset();
		
		margin-left: -16px;

		
		> li
		{
			display: block;
			float: left;

			> a
			{
				line-height: 2em;
				padding: 0 16px;
			}
		}
		
		ul
		{
			display: none;
		}
	}
}
#etf-subnav-lo > .etf-container
{
	@include container-base(0.125);
	@include link-color($color-white, $color-turquoise);

	font-size: 1.167em;

	> ul
	{
		@include menu-reset();

		margin-left: -16px;


		> li
		{
			display: block;
			float: left;

			> a
			{
				line-height: 2em;
				padding: 0 16px;
			}
		}

		ul
		{
			display: none;
		}
	}
}
.etf-terminal-nav
{
  @include link-color($color-dark-slate, $color-orange);
  color: $color-turquoise;
  font-weight: bold;
  span:hover{
    color: $color-orange;
  }
  font-size: 1.3em;
	> ul
	{
		@include menu-reset();

		margin-left: -16px;

		> li
		{
			display: block;
			float: left;
			a{
			  padding-top: 6px;
			}

			> span
			{
			  cursor: pointer;
				line-height: 2em;
				padding: 0 16px;
			}
		}
		> li.active{
		  color: $color-orange;
		  font-weight: bold;
		}

		ul
		{
			display: none;
		}
	}
}

.etf-terminal-subnav
// TODO: MERGE
//  {
//  	&.light{
//  	  background-color: $color-blue;
//      li.active{
//        color: $color-orange;
//        font-weight: bold;
//      }
//  	}
//  	font-size: 1em;
//  	> ul
//  	{
//  		@include menu-reset();

{
	&.light{
	  border-bottom: thin solid $color-gray;
    li.active{
      font-weight: bold;
      border-bottom: solid #f18f01;
    }
	}
	font-size: 1em;
	> ul
	{
		@include menu-reset();

 		margin-left: -16px;

 		> li
 		{
 			display: inline-block;
 // 			float: left;

 			a{
 			  padding-top: 6px;
 			}
 			// TODO: MERGE
//  			> span
//  			{
//  			  cursor: pointer;
//  				line-height: 2em;
//  				padding: 0 16px;
//  			}
//  		}
//  		> li.active{
//  		  color: $color-orange;
//  		  font-weight: bold;
//  		}
			> span
			{
			  cursor: pointer;
				line-height: 2em;
				padding: 0 16px;
			}
		}
		> li.active{
// 		  color: $color-orange;
		  font-weight: bold;
		}
 		ul
 		{
 			display: none;
 		}
 	}
 }
 .etf-mMkt-subnav
 {
 border-bottom: thin solid $color-gray;
 	&.light{
 	  background-color: #fafafa;

     li.active{
       color: $color-orange;
       font-weight: bold;
       border-bottom: solid #f18f01;
     }
 	}
 	font-size: 1em;
 	> ul
 	{
 		@include menu-reset();

 		margin-left: -16px;

 		> li
 		{
 			display: inline-block;
 			
 // 			float: left;

 			a{
 			  padding-top: 6px;
 			}

 			> span
 			{
 			  cursor: pointer;
 				line-height: .5em;
 				padding: 0 8px;
 			}
 		}
 		> li.active{
 		  color: $color-orange;
 		  font-weight: bold;
 		  border-bottom: solid #f18f01;
 		}

 		ul
 		{
 			display: none;
 		}
 	}
 }

 .etf-mu-subnav
  {
  border-bottom: thin solid $color-gray;
   	&.light{
   	  background-color: #fafafa;

       li.active{
         color: $color-orange;
         font-weight: bold;
         border-bottom: solid #f18f01;
       }
   	}
   	font-size: 1em;
   	> ul
   	{
   		@include menu-reset();

   		margin-left: -16px;

   		> li
   		{
   			display: inline-block;

   // 			float: left;

   			a{
   			  padding-top: 6px;
   			}

   			> span
   			{
   			  cursor: pointer;
   				line-height: .5em;
   				padding: 0 8px;
   			}
   		}
   		> li.active{
   		  color: $color-orange;
   		  font-weight: bold;
   		  border-bottom: solid #f18f01;
   		}

   		ul
   		{
   			display: none;
   		}
   	}
  }
/* ::-webkit-scrollbar {
  height: 8px;               *//* height of horizontal scrollbar ← You're missing this *//*
  width: 4px;                *//* width of vertical scrollbar *//*
//   border: 1px solid #d5d5d5;
  background: white;
}

::-webkit-scrollbar-track {
    background-color: #fca5a5;
}
::-webkit-scrollbar-button:horizontal:start:increment {
    display: block;
}

::-webkit-scrollbar-button {
    display: block;
    background-color: #b91c1c;
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: center;
} */
.etf-terminal-subnav {
  overflow-x: auto;
  white-space: nowrap;
  padding: 0px;
  scrollbar-width: thin;
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.25);
  border: thin solid $color-gray;
//   color: $color-white;
//   background-color: $color-dark-blue;
}

.etf-terminal-subnav ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}
.etf-terminal-subnav ul li {
//   margin-right: 20px;
  padding: 8px 12px;
  &.active{
    border-bottom: solid #f18f01;
  }
}

.etf-terminal-subnav ul li:last-child {
  margin-right: 0;
}
/* Adjust the breakpoint as needed */
@media (max-width: 768px) {
  .etf-terminal-subnav {
    overflow-x: scroll;
    white-space: nowrap;
  }

  .etf-terminal-subnav ul li {
    display: inline-block;
    &.active{
      border-bottom: solid #f18f01;
    }
  }
}
.etf-terminal-subnav::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #00c4ec;
}

.etf-terminal-subnav::-webkit-scrollbar-track {
  background-color: #FFFFFF;
}
.etf-terminal-subnav::-webkit-scrollbar-thumb {
  background-color: #00c4ec;
}
.etf-terminal-subnav::-webkit-scrollbar-thumb:hover {
  background-color: #00c4ec;
}

// @media (max-width: 1024px){
// #site-content{
//     .etf-terminal-subnav{
//       white-space: nowrap;
//       overflow-x: auto;
//       scrollbar-width: thin;
//     }
//     .etf-terminal-subnav::-webkit-scrollbar {
//       display: none;
//     }
//   }
// }
//
// /* Hide the scrollbar on mobile devices */
// @media (max-width: 768px) {
//   .etf-terminal-subnav{
//     scrollbar-width: none; /* Firefox */
//     -ms-overflow-style: none; /* IE and Edge */
//   }
// }
//
// /* Style the scrollbar for other devices */
// .etf-terminal-subnav::-webkit-scrollbar {
//   width: 8px;
//   background-color: #f18f01;
// }
// .etf-terminal-subnav::-webkit-scrollbar-track {
//   background-color: #f18f01;
// }
// .etf-terminal-subnav::-webkit-scrollbar-thumb {
//   background-color: #f18f01;
// }
// .etf-terminal-subnav::-webkit-scrollbar-thumb:hover {
//   background-color: #f18f01;
// }
//
// .etf-terminal-subnav
// {
//   @include link-color($color-white, $color-turquoise);
//   color: $color-white;
//   background-color: $color-dark-blue;
//   white-space: nowrap;
//   overflow-x: auto;
//   scrollbar-width: thin;
//   ul
//   {
//     margin: 0 auto;
//
//     padding: 0;
//     list-style: none;
//     li
//     {
// //       display: block;
// //       float: left;
//       padding: 0px;
//       display: inline-block;
//       margin-right: 0px;
//     }
//   }
// //   ul::-webkit-scrollbar {
// //     display: none;
// //   }
//
//   span:hover,span.active{
//     color: $color-turquoise;
//   }
// }


// TODO THis one works
//
// @media (max-width: 1024px){
// #site-content{
//     .etf-terminal-subnav{
//         ul
//         {
//           //overflow: auto;
//           overflow-x: auto;
//           -webkit-overflow-scrolling: touch;
//         }
//         ul::-webkit-scrollbar {
//           display: none;
//         }
//     }
//   }
// }
//
//
// .etf-terminal-subnav
// {
//   @include link-color($color-white, $color-turquoise);
//   color: $color-white;
//   background-color: $color-dark-blue;
//   ul
//   {
//     margin: 0 auto;
//     white-space:nowrap;
//     overflow-x: auto;
//     -webkit-overflow-scrolling: touch;
//     padding: 0;
//     list-style: none;
//     li
//     {
// //       display: block;
// //       float: left;
//       padding: 0px;
//       display: inline-block;
//       margin-right: 0px;
//     }
//   }
//   ul::-webkit-scrollbar {
//     display: none;
//   }
//
//   span:hover,span.active{
//     color: $color-turquoise;
//   }
// }
