.etf-lightbox
{
	@include box-shadow(1px, 1px, 2px, 1px, rgba($color-black, 0.5));
	
	&.etf-team-lightbox
	{
		max-width: $width-pc-portrait;
	}
	
	&-arrows a
	{
		@include arrow($color-quarter-gray, $color-half-gray);
		
		margin-top: -1.137em;
		position: absolute;
		top: 50%;
		
		&.etf-lightbox-previous
		{
			@include rotate(90deg);
			
			left: 0;
		}
		
		&.etf-lightbox-next
		{
			@include rotate(-90deg);
			
			right: 0;
		}
	}
	
	&-content a:not(:last-child)
	{
		margin-right: 16px;
	}
}
