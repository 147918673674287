@mixin arrow($color, $hover-color: '')
{
	display: inline-block;
	height: 1.227em;
	overflow: hidden;
	position: relative;
	text-indent: -99999px;
	width: 2.273em;

	&::before,
	&::after
	{
		@include border-radius(0.833em);
		@include transition(background);
		
		background: $color;
		bottom: 0;
		content: ' ';
		display: block;
		height: 0.167em;
		position: absolute;
		width: 66.667%;
	}
	
	&::before
	{
		@include rotate(45deg);
		@include transform-origin(100% 50%);
		
		right: 48.5%;
	}
	
	&::after
	{
		@include rotate(-45deg);
		@include transform-origin(0% 50%);
		
		left: 48.5%;
	}
	
	@if ($hover-color != '')
	{
		&:hover
		{
			&::before,
			&::after
			{
				background: $color-half-gray;
			}
		}
	}
}

@mixin bootstrap-columns($size)
{
	@for $i from 1 through 12
	{
		.etf-col-#{$size}-#{$i}
		{
			width: percentage($i / 12)
		}
	}
}

@mixin container-base($vertical-multiplier: 1, $bottom-multiplier: false)
{
	@include border-box();
	
	margin: 0 auto;
	max-width: $width-site;
	
	@include bp(above-pc-portrait)
	{
		padding-right: 32px;
		padding-left: 32px;
	}

	@include bp(tablet-portrait-to-pc-portrait)
	{
		padding-right: 24px;
		padding-left: 24px;
	}

	@include bp(up-to-tablet-portrait)
	{
		padding-right: 16px;
		padding-left: 16px;
	}
	
	@if ($bottom-multiplier == false)
	{
		$bottom-multiplier: $vertical-multiplier;
	}

	@include bp(above-pc-portrait)
	{
		&:first-child
		{
			padding-top: round(64 * $vertical-multiplier) + px;
		}

		padding-bottom: round(64 * $bottom-multiplier) + px;
	}

	@include bp(tablet-portrait-to-pc-portrait)
	{
		&:first-child
		{
			padding-top: round(48 * $vertical-multiplier) + px;
		}

		padding-bottom: round(48 * $bottom-multiplier) + px;
	}

	@include bp(up-to-tablet-portrait)
	{
		&:first-child
		{
			padding-top: round(32 * $vertical-multiplier) + px;
		}

		padding-bottom: round(32 * $bottom-multiplier) + px;
	}
}
@mixin container-base-thin($vertical-multiplier: 1, $bottom-multiplier: false)
{
	@include border-box();

	margin: 0 auto;
	max-width: $width-site;

	@include bp(above-pc-portrait)
	{
		padding-right: 32px;
		padding-left: 32px;
	}

	@include bp(tablet-portrait-to-pc-portrait)
	{
		padding-right: 24px;
		padding-left: 24px;
	}

	@include bp(up-to-tablet-portrait)
	{
		padding-right: 16px;
		padding-left: 16px;
	}

	@if ($bottom-multiplier == false)
	{
		$bottom-multiplier: $vertical-multiplier;
	}

	@include bp(above-pc-portrait)
	{
		&:first-child
		{
			padding-top: round(64 * $vertical-multiplier) + px;
		}

		padding-bottom: round(64 * $bottom-multiplier) + px;
	}

	@include bp(tablet-portrait-to-pc-portrait)
	{
		&:first-child
		{
			padding-top: round(48 * $vertical-multiplier) + px;
		}

		padding-bottom: round(48 * $bottom-multiplier) + px;
	}

	@include bp(up-to-tablet-portrait)
	{
		&:first-child
		{
			padding-top: round(32 * $vertical-multiplier) + px;
		}

		padding-bottom: round(32 * $bottom-multiplier) + px;
	}
}

@mixin size-and-margin($element-font-size, $margin-multiplier: 1, $font-size: $font-base-size)
{
	$line-height: (($font-line-height-max - $font-line-height-min) * (1 - (($element-font-size - $font-base-size) / ($font-base-size * 2)))) + $font-line-height-min;

	margin-bottom: (($font-size / $element-font-size) * ($font-base-margin * $margin-multiplier)) + em;
	font-size: ($element-font-size / $font-size) + em;
	line-height: max(min($line-height, $font-line-height-max), $font-line-height-min) + em;
}
