.etf-down-arrow
{
	@include arrow($color-orange);
	
	padding-top: 0.25em;
}

a:hover .etf-down-arrow
{
	&::before,
	&::after
	{
		background: $color-dark-orange;
	}
}
