#etf-our-vision
{
	> .etf-container
	{
		&:not(:last-child)
		{
			@include container-base(1.25, 1);
		}
		
		&:last-child
		{
			@include container-base(1, 1.5);
		}
		
		[class^='etf-col-']
		{
			text-align: center;

			h4
			{
				display: inline-block;
				padding: 0.25em 0 0.25em 3.5em;
				position: relative;
				text-align: left;
				
				@include bp(tablet-to-site)
				{
					br
					{
						display: none;
					}
				}
				
				@include bp(up-to-phone)
				{
					br
					{
						display: none;
					}
				}

				[class^="etf-icon-"],
				[class*=" etf-icon-"]
				{
					@include border-box();
					@include border-radius();

					background: $color-dark-blue;
					border: 2px solid $color-turquoise;
					color: $color-white;
					font-size: 1.5em;
					height: 2em;
					left: 0;
					line-height: 2em;
					padding: 0;
					position: absolute;
					text-align: center;
					top: 0;
					width: 2em;

					&::before
					{
						position: relative;
						top: -2px;
					}
				}
			}
		}
	}
}
