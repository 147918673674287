.fancybox-is-open .fancybox-bg
{
	opacity: 0.4;
}

.fancybox-slide--html .fancybox-close-small
{
	height: 44px;
	outline: 0;
	padding: 0;
	width: 44px;
	
	&::before,
	&::after
	{
		@include transition(background);
		
		background: $color-quarter-gray;
		content: ' ';
		display: block;
		height: 2px;
		left: 20%;
		margin-top: -1px;
		position: absolute;
		top: 50%;
		width: 60%;
	}
	
	&:hover
	{
		background: none;
		
		&::before,
		&::after
		{
			background: $color-half-gray;
		}
	}
	
	&::before
	{
		@include rotate(45deg);
	}
	
	&::after
	{
		@include rotate(-45deg);
	}
	
	svg
	{
		display: none;
	}
}
