input[type='email'],
input[type='password'],
input[type='tel'],
input[type='text'],
select,
textarea
{
	@include border-box();
	
	background: $color-white;
	border: 1px solid $color-dark-slate;
	color: inherit;
	line-height: inherit;
	margin: 0;
	max-width: 100%;
	outline: 0;
	padding: 0.375em 0.5em;
	
	&.etf-error
	{
		border-color: $color-red !important;
	}
}
#captcha_field .info{
  display: none;
}

select
{
	padding: 0.25em 0.375em;
}

label
{
	&.etf-error,
	&.etf-message
	{
		color: $color-red;
		display: block;
		font-size: 0.875em;
		font-weight: 600;
	}
}
.etf-play-form{
  &.small-form{
    max-width: 400px;
    margin: auto;
  }
  .form-control.small {
    display: inline-block;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 0px;
    dd {
      display: inline;
      margin-left: 2px;
    }
  }
  .form-terms{
    display: inline;
  }
  dd{
    margin-left: 0px;
  }
  dt{
    display: none;
  }
  dl{
    margin: 5px 0px 5px 0px;
  }
}
.etf-play-form-show{
  &.small-form{
    max-width: 400px;
    margin: auto;
  }
  .form-control.small {
    display: inline-block;
    padding-left: 10px;
    padding-right: 10px;
    margin-bottom: 5px;
    dd {
      display: inline;
      margin-left: 2px;
    }
  }
  .form-terms{
    display: inline;
  }
  dd{
    margin-left: 0px;
  }
  dt{
    margin-bottom: 3px;
  }

  dl{
    margin: 5px 0px 10px 0px;
  }
}
