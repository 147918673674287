.gated#mobile-main-menu-toggle{
  @include bp(above-tablet-portrait)
  {
    padding: 14px;
  }
}
.etf-scrolled .gated#mobile-main-menu-toggle{
  @include bp(above-tablet-portrait)
  {
    padding: 14px;
  }
}

#mobile-main-menu-toggle
{
	@include bp(above-pc-portrait)
	{
		display: none;
	}
	
	@include bp(up-to-pc-portrait)
	{
		@include border-box();
		@include transition(padding);
		
		cursor: pointer;
		float: right;
		margin-right: -16px;
		
		@include bp(above-tablet-portrait)
		{
			padding: 16px;
		}



		@include bp(up-to-tablet-portrait)
		{
			padding: 14px 16px;
		}

		> div
		{
			height: 20px;
			position: relative;
			width: 30px;
			> span
			{
				@include border-radius(3px);
				@include transition(all, 200ms);

				background: $color-logo;
				height: 15%;
				left: 0;
				position: absolute;
				top: 40%;
				width: 100%;

				&:first-child
				{
					top: 0;
				}

				&:last-child
				{
					top: 80%;
				}
			}
		}
		
		&:hover,
		&.etf-open
		{
			> div > span
			{
				background: $color-turquoise;
			}
		}
		
		&.etf-open > div > span
		{
			&:first-child
			{
				height: 0;
				opacity: 0;
				top: -40%;
			}
			
			&:nth-child(2)
			{
				@include rotate(45deg);
			}
			
			&:nth-last-child(2)
			{
				@include rotate(-45deg);
			}

			&:last-child
			{
				height: 0;
				opacity: 0;
				top: 120%;
			}
		}
	}
}

.etf-scrolled #mobile-main-menu-toggle
{
	@include bp(above-tablet-portrait)
	{
		padding-bottom: 17px;
		padding-top: 5px;
	}

	@include bp(up-to-tablet-portrait)
	{
		padding-bottom: 16px;
		padding-top: 16px;
	}
}

#mobile-main-menu
{
	@include bp(above-pc)
	{
		display: none !important;
	}
	
	@include bp(up-to-pc)
	{
		@include box-shadow(-2px, 2px, 4px, 0, $color-shadow);
		
		background: $color-slate;
		display: none;
		font-size: 18px;
		max-width: 100%;
		min-width: 240px;
		overflow: auto;
		position: absolute;
		right: 0;
		top: 100%;
		-webkit-overflow-scrolling: touch;
		
		> ul
		{
			@include link-color($color-white, $color-turquoise);
			@include menu-reset();
			
			> li
			{
				&.etf-top-menu-item
				{
					font-family: $font-heading;
					
					@include bp(above-tablet-portrait)
					{
						display: none;
					}
					
					@include bp(up-to-tablet-portrait)
					{
						display: block;
					}
				}
				
				> ul
				{
					font-size: 0.833em;
				}
			}
			
			li
			{
				text-align: right;
				
				&:first-child > a
				{
					padding-top: 16px;
				}
				
				&:last-child > a
				{
					padding-bottom: 16px;
				}
				
				&.etf-has-children
				{
					> a::after
					{
						color: $color-turquoise;
						display: inline-block;
						font-weight: bold;
						text-align: right;
						width: 0.75em;
					}
					
					&:not(.etf-open) > a::after
					{
						content: '+';
					}
					
					&.etf-open > a::after
					{
						content: '–';
					}
				}
			}
			
			a
			{
				&:not(.etf-button)
				{
					padding-bottom: 8px;
					padding-top: 8px;
					text-align: right;

					@include bp(above-pc-portrait)
					{
						padding-left: 32px;
						padding-right: 32px;
					}

					@include bp(tablet-portrait-to-pc-portrait)
					{
						padding-left: 24px;
						padding-right: 24px;
					}

					@include bp(up-to-tablet-portrait)
					{
						padding-left: 16px;
						padding-right: 16px;
					}
				}
				
				&.etf-button
				{
					display: inline-block;
					margin-bottom: 4px;
					margin-top: 4px;
					padding: 0.45em 0.75em;
					
					@include bp(above-pc-portrait)
					{
						margin-left: 24px;
						margin-right: 24px;
					}

					@include bp(above-tablet-portrait)
					{
						margin-bottom: 16px;
					}

					@include bp(tablet-portrait-to-pc-portrait)
					{
						margin-left: 16px;
						margin-right: 16px;
					}

					@include bp(up-to-tablet-portrait)
					{
						margin-left: 8px;
						margin-right: 8px;
					}
				}
			}
			
			ul
			{
				display: none;
				background: $color-light-slate;
			}
		}
	}
}
