#site-header
{
	@include box-shadow(0, 2px, 4px, 0, $color-shadow);
	
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100;
	width: 100%;

	min-width: $width-min;


	background: $color-white;
	
	> header
	{
		/*@include container-base(0);*/
    padding-left: 30px;
    padding-right: 50px;

		position: relative;
	}
}

#site-logo
{
	@include transition([opacity, padding]);
	
	margin: 0 -16px;
	display: block;
	float: left;
	
	@include bp(above-pc-portrait)
	{
		padding: 24px 16px;
	}
	
	@include bp(tablet-portrait-to-pc-portrait)
	{
		padding: 18px 16px;
	}
	
	@include bp(up-to-tablet-portrait)
	{
		padding: 12px 16px;
	}
	
	&:hover
	{
		opacity: 0.75;
	}
	
	img
	{
		@include transition(max-width);
		
		display: block;
		
		@include bp(tablet-portrait-to-pc-portrait)
		{
			max-width: 216px;
		}

		@include bp(up-to-tablet-portrait)
		{
			max-width: 166px;
		}
	}
}

.etf-scrolled #site-logo
{
	@include bp(above-pc-portrait)
	{
		padding: 18px 16px;
		
		img
		{
			max-width: 213px;
		}
	}
	
	@include bp(tablet-portrait-to-pc-portrait)
	{
		padding: 12px 16px;
		
		img
		{
			max-width: 173px;
		}
	}
	
	@include bp(up-to-tablet-portrait)
	{
		padding: 6px 16px;
		
		img
		{
			max-width: 133px;
		}
	}
}

#site-logo-small
{
	@include transition([opacity, padding]);

	margin: 0 -16px;
	display: block;
	float: left;

	@include bp(above-pc-portrait)
	{
		padding: 6px 4px;
	}

	@include bp(tablet-portrait-to-pc-portrait)
	{
		padding: 4px 4px;
	}

	@include bp(up-to-tablet-portrait)
	{
		padding: 2px 2px;
	}

	&:hover
	{
		opacity: 0.75;
	}

	img
	{
		@include transition(max-width);

		display: block;

		@include bp(tablet-portrait-to-pc-portrait)
		{
			max-width: 150px;
		}

		@include bp(up-to-tablet-portrait)
		{
			max-width: 140px;
		}
	}
}
.etf-scrolled #site-logo-small
{
	@include bp(above-pc-portrait)
	{
		padding: 14px 12px;

		img
		{
			max-width: 150px;
		}
	}

	@include bp(tablet-portrait-to-pc-portrait)
	{
		padding: 10px 12px;

		img
		{
			max-width: 150px;
		}
	}

	@include bp(up-to-tablet-portrait)
	{
		padding: 6px 12px;

		img
		{
			max-width: 130px;
		}
	}
}
