$width-site: 1264px;
$width-pc: 1128px;
$width-pc-portrait: 992px;
$width-admin-bar: 782px;
$width-tablet: 768px;
$width-tablet-portrait: 624px;
$width-phone: 480px;
$width-phone-portrait: 390px;
$width-min: 300px;

@mixin bp($breakpoint)
{
	@if ($breakpoint == above-site)
	{
    	@media (min-width: $width-site + 1)
		{
			@content;
		}
	}
	
	@if ($breakpoint == up-to-site)
	{
    	@media (max-width: $width-site)
		{
			@content;
		}
	}
	
	@if ($breakpoint == above-pc)
	{
    	@media (min-width: $width-pc + 1)
		{
			@content;
		}
	}
	
	@if ($breakpoint == up-to-pc)
	{
    	@media (max-width: $width-pc)
		{
			@content;
		}
	}
	
	@if ($breakpoint == above-pc-portrait)
	{
    	@media (min-width: $width-pc-portrait + 1)
		{
			@content;
		}
	}
	
	@if ($breakpoint == up-to-pc-portrait)
	{
    	@media (max-width: $width-pc-portrait)
		{
			@content;
		}
	}
	
	@if ($breakpoint == above-admin-bar)
	{
    	@media (min-width: $width-admin-bar + 1)
		{
			@content;
		}
	}
	
	@if ($breakpoint == up-to-admin-bar)
	{
    	@media (max-width: $width-admin-bar)
		{
			@content;
		}
	}
	
	@if ($breakpoint == above-tablet)
	{
    	@media (min-width: $width-tablet + 1)
		{
			@content;
		}
	}
	
	@if ($breakpoint == up-to-tablet)
	{
    	@media (max-width: $width-tablet)
		{
			@content;
		}
	}
	
	@if ($breakpoint == above-tablet-portrait)
	{
    	@media (min-width: $width-tablet-portrait + 1)
		{
			@content;
		}
	}
	
	@if ($breakpoint == up-to-tablet-portrait)
	{
    	@media (max-width: $width-tablet-portrait)
		{
			@content;
		}
	}
	
	@if ($breakpoint == above-phone)
	{
    	@media (min-width: $width-phone + 1)
		{
			@content;
		}
	}
	
	@if ($breakpoint == up-to-phone)
	{
    	@media (max-width: $width-phone)
		{
			@content;
		}
	}
	
	@if ($breakpoint == above-phone-portrait)
	{
    	@media (min-width: $width-phone-portrait + 1)
		{
			@content;
		}
	}
	
	@if ($breakpoint == up-to-phone-portrait)
	{
    	@media (max-width: $width-phone-portrait)
		{
			@content;
		}
	}
	
	@if ($breakpoint == pc-to-site)
	{
		@media (min-width: $width-pc + 1) and (max-width: $width-site)
		{
			@content;
		}
	}
	
	@if ($breakpoint == pc-portrait-to-site)
	{
		@media (min-width: $width-pc-portrait + 1) and (max-width: $width-site)
		{
			@content;
		}
	}
	
	@if ($breakpoint == pc-portrait-to-pc)
	{
		@media (min-width: $width-pc-portrait + 1) and (max-width: $width-pc)
		{
			@content;
		}
	}
	
	@if ($breakpoint == admin-bar-to-site)
	{
		@media (min-width: $width-admin-bar + 1) and (max-width: $width-site)
		{
			@content;
		}
	}
	
	@if ($breakpoint == admin-bar-to-pc)
	{
		@media (min-width: $width-admin-bar + 1) and (max-width: $width-pc)
		{
			@content;
		}
	}
	
	@if ($breakpoint == admin-bar-to-pc-portrait)
	{
		@media (min-width: $width-admin-bar + 1) and (max-width: $width-pc-portrait)
		{
			@content;
		}
	}
	
	@if ($breakpoint == tablet-to-site)
	{
		@media (min-width: $width-tablet + 1) and (max-width: $width-site)
		{
			@content;
		}
	}
	
	@if ($breakpoint == tablet-to-pc)
	{
		@media (min-width: $width-tablet + 1) and (max-width: $width-pc)
		{
			@content;
		}
	}
	
	@if ($breakpoint == tablet-to-pc-portrait)
	{
		@media (min-width: $width-tablet + 1) and (max-width: $width-pc-portrait)
		{
			@content;
		}
	}
	
	@if ($breakpoint == tablet-to-admin-bar)
	{
		@media (min-width: $width-tablet + 1) and (max-width: $width-admin-bar)
		{
			@content;
		}
	}
	
	@if ($breakpoint == tablet-portrait-to-site)
	{
		@media (min-width: $width-tablet-portrait + 1) and (max-width: $width-site)
		{
			@content;
		}
	}
	
	@if ($breakpoint == tablet-portrait-to-pc)
	{
		@media (min-width: $width-tablet-portrait + 1) and (max-width: $width-pc)
		{
			@content;
		}
	}
	
	@if ($breakpoint == tablet-portrait-to-pc-portrait)
	{
		@media (min-width: $width-tablet-portrait + 1) and (max-width: $width-pc-portrait)
		{
			@content;
		}
	}
	
	@if ($breakpoint == tablet-portrait-to-admin-bar)
	{
		@media (min-width: $width-tablet-portrait + 1) and (max-width: $width-admin-bar)
		{
			@content;
		}
	}
	
	@if ($breakpoint == tablet-portrait-to-tablet)
	{
		@media (min-width: $width-tablet-portrait + 1) and (max-width: $width-tablet)
		{
			@content;
		}
	}
	
	@if ($breakpoint == phone-to-site)
	{
		@media (min-width: $width-phone + 1) and (max-width: $width-site)
		{
			@content;
		}
	}
	
	@if ($breakpoint == phone-to-pc)
	{
		@media (min-width: $width-phone + 1) and (max-width: $width-pc)
		{
			@content;
		}
	}
	
	@if ($breakpoint == phone-to-pc-portrait)
	{
		@media (min-width: $width-phone + 1) and (max-width: $width-pc-portrait)
		{
			@content;
		}
	}
	
	@if ($breakpoint == phone-to-admin-bar)
	{
		@media (min-width: $width-phone + 1) and (max-width: $width-admin-bar)
		{
			@content;
		}
	}
	
	@if ($breakpoint == phone-to-tablet)
	{
		@media (min-width: $width-phone + 1) and (max-width: $width-tablet)
		{
			@content;
		}
	}
	
	@if ($breakpoint == phone-to-tablet-portrait)
	{
		@media (min-width: $width-phone + 1) and (max-width: $width-tablet-portrait)
		{
			@content;
		}
	}
	
	@if ($breakpoint == phone-portrait-to-site)
	{
		@media (min-width: $width-phone-portrait + 1) and (max-width: $width-site)
		{
			@content;
		}
	}
	
	@if ($breakpoint == phone-portrait-to-pc)
	{
		@media (min-width: $width-phone-portrait + 1) and (max-width: $width-pc)
		{
			@content;
		}
	}
	
	@if ($breakpoint == phone-portrait-to-pc-portrait)
	{
		@media (min-width: $width-phone-portrait + 1) and (max-width: $width-pc-portrait)
		{
			@content;
		}
	}
	
	@if ($breakpoint == phone-portrait-to-admin-bar)
	{
		@media (min-width: $width-phone-portrait + 1) and (max-width: $width-admin-bar)
		{
			@content;
		}
	}
	
	@if ($breakpoint == phone-portrait-to-tablet)
	{
		@media (min-width: $width-phone-portrait + 1) and (max-width: $width-tablet)
		{
			@content;
		}
	}
	
	@if ($breakpoint == phone-portrait-to-tablet-portrait)
	{
		@media (min-width: $width-phone-portrait + 1) and (max-width: $width-tablet-portrait)
		{
			@content;
		}
	}
	
	@if ($breakpoint == phone-portrait-to-phone)
	{
		@media (min-width: $width-phone-portrait + 1) and (max-width: $width-phone)
		{
			@content;
		}
	}
}

#site-breakpoint
{
	height: 0;
	width: 1px;
	
	@include bp(pc-to-site)
	{
		height: 1px;
	}
	
	@include bp(pc-portrait-to-pc)
	{
		height: 2px;
	}
	
	@include bp(admin-bar-to-pc-portrait)
	{
		height: 3px;
	}
	
	@include bp(tablet-to-admin-bar)
	{
		height: 4px;
	}
	
	@include bp(tablet-portrait-to-tablet)
	{
		height: 5px;
	}
	
	@include bp(phone-to-tablet-portrait)
	{
		height: 6px;
	}
	
	@include bp(phone-portrait-to-phone)
	{
		height: 7px;
	}
	
	@include bp(up-to-phone-portrait)
	{
		height: 8px;
	}
}
