@import 'vendor/jquery.fancybox';
@import 'vendor/normalize';

/*! ETF Action */

$theme-prefix: etf;

@import 'mixins/functions';
@import 'mixins/general';
@import 'mixins/theme';
@import 'mixins/breakpoints';

@import 'includes/animations';
@import 'includes/colors';
@import 'includes/icons';
@import 'includes/fonts';
@import 'includes/forms';
@import 'includes/classes';
@import 'includes/elements';

@import 'layout/general';
@import 'layout/header';
@import 'layout/navigation';
@import 'layout/mobile-menu';
@import 'layout/content';
@import 'layout/footer';
@import 'layout/copyright';

@import 'sections/banner';
@import 'sections/hero';
@import 'sections/our-vision';
@import 'sections/principles';
@import 'sections/research';
@import 'sections/sign-up';
@import 'sections/subnav';
@import 'sections/daily-note';
@import 'sections/terminal';
@import 'sections/my-account';

@import 'components/columns';
@import 'components/accordion';
@import 'components/tables';
@import 'components/content-sum';
@import 'components/pricing-plan';
@import 'components/down-arrow';
@import 'components/items';
@import 'components/lightboxes';
@import 'components/slideshow';
@import 'components/team-link';
@import 'components/video-link';
@import 'components/pdf';
@import 'components/forms';
@import 'components/modal';

/*@import 'admin/content';*/


@import 'plugins/fancybox';
@import 'plugins/nice-scroll';

@include solid-elements();

#site-content
{
	@include fragile-elements();
}
