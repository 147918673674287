.etf-section
{
	background: $color-white none no-repeat center center;
	background-size: cover;
	position: relative;
	z-index: 98;
	
	&.etf-dark-blue-section
	{
		background-color: $color-dark-blue;
		color: $color-white;
	}
	
	&.etf-light-gray-section
	{
		background-color: $color-light-gray;
	}
	
	&.etf-light-slate-section
	{
		background-color: $color-light-slate;
		color: $color-white;
	}
	
	&.etf-reverse-section
	{
		background-color: $color-dark-slate;
		color: $color-white;
	}

	.content-card{
	  /*box-shadow: 0px 0px 2px 6px rgba(0,0,0,0.25);*/

	  padding: 20px;
    box-shadow: 0 15px 20px 0 rgba(0,0,0,.18);
    border-top: 15px solid $color-light-slate;
	  height: 380px;
	  position: relative;
	  .etf-button{
	    position: absolute;
	    bottom: 20px;
	  }
	}
}

.etf-section-gated
{
	background: $color-white none no-repeat center center;
	background-size: cover;
	position: relative;
	z-index: 98;

	&.etf-dark-blue-section
	{
		background-color: $color-dark-blue;
		color: $color-white;
	}

	&.etf-light-gray-section
	{
		background-color: $color-light-gray;
	}

	&.etf-light-slate-section
	{
		background-color: $color-light-slate;
		color: $color-white;
	}

	&.etf-reverse-section
	{
		background-color: $color-dark-slate;
		color: $color-white;
	}
}
.etf-full-width{
  padding: 32px;
  width: 100%;

}

.etf-container
{
	@include container-base();

	&.etf-short-container
	{
		@include container-base(0.5);
	}
	&.etf-wide-container
	{
	  width: 100%;
	  max-width: 100%;
	  margin: 0px 0px;
	  padding-left: 0px;
	  padding-top: 10px;
	  overflow: hidden;
    position: relative;
	}

	&.etf-full-container
	{
	  width: 100%;
	  max-width: 100%;
	}

	&.etf-thin-container
	{
		max-width: $width-pc;
	}
}
