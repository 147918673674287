#site-copyright
{
	color: $color-logo;
	font-size: 15px;
	position: relative;
	z-index: 99;
	
	footer
	{
		@include container-base(0.25);
	}
}
