

.pricing-plan{
  position: relative;
  min-height: 675px;
  margin: 5px;

  border-radius: 10px;

  background: #fff;
  //box-shadow: 0 15px 20px 0 rgba(0,0,0,.18);
  box-shadow: 0 15px 20px 10px rgba(0,0,0,.18);
  &.main{
    box-shadow: 0 15px 20px 10px rgba(0,196,236,0.85);
  }
  //border-top: 15px solid #1c2634;
  //padding: 25px 40px;

  .plan-header{
    text-align: center;
    border-radius: 10px 10px 0 0;
    height: 120px;
    padding: 20px 30px;
    background-color: #1c2634;
    color: $color-white;
    h3{
      color: $color-turquoise;

    }
    p{
      margin-bottom: 5px;
      font-size: 1.6em;

    }
    &.main{
      h3{
        color: $color-orange;
      }
    }

  }
  .plan-body{
    padding: 20px 30px;
    min-height: 630px;

    color: $color-dark-slate;
    .plan-desc{
      padding-bottom: 10px;
      min-height: 120px;
      border-bottom: 2px solid $color-orange;
    }
    p{

    }
  }
  .plan-footer{
    padding: 20px 30px;
  }
}