#etf-sign-up > .etf-container
{
	@include container-base(0.5);
	
	.etf-field
	{
		overflow: hidden;
		
		&:first-child
		{
			float: left;
			padding-right: 0.5em;
		}
		
		input
		{
			border-color: $color-white;

			@include bp(above-tablet)
			{
				width: 340px;
			}
			
			@include bp(phone-to-tablet)
			{
				width: 255px;
			}
			
			@include bp(up-to-phone)
			{
				width: 170px;
			}
		}
	}
}
