@mixin solid-elements()
{
	@include link-color($color-orange, $color-dark-orange);
	
	a
	{
		@include transition(color);
		
		outline: 0;
		text-decoration: none;
	}
	
	h1,
	h2,
	h3,
	h4,
	h5,
	h6,
	li,
	ol,
	p,
	ul
	{
		margin-top: 0;

		&:last-child,
		&.etf-no-margin
		{
			margin-bottom: 0;
		}
		
		&.etf-half-margin
		{
			margin-bottom: 0.5em;
		}
		
		small,
		span
		{
			line-height: normal;
		}
	}
	
	h1
	{
		font-weight: normal;
		font-family: $font-heading;
		
		@include bp(above-pc-portrait)
		{
			@include size-and-margin(53);
		}
		
		@include bp(tablet-portrait-to-pc-portrait)
		{
			@include size-and-margin(44);
		}
		
		@include bp(up-to-tablet-portrait)
		{
			@include size-and-margin(35);
		}
		
		em
		{
			color: $color-turquoise;
			font-style: normal;
		}

	}
	
	h2
	{
		@include size-and-margin(40);
		
		color: $color-turquoise;
		font-weight: normal;
		font-family: $font-heading;
		
		@include bp(above-pc-portrait)
		{
			@include size-and-margin(40);
		}
		
		@include bp(tablet-portrait-to-pc-portrait)
		{
			@include size-and-margin(34);
		}
		
		@include bp(up-to-tablet-portrait)
		{
			@include size-and-margin(28);
		}
	}
	
	h3
	{
		font-weight: normal;
		font-family: $font-heading;
		
		@include bp(above-pc-portrait)
		{
			@include size-and-margin(28, 0.5);
		}
		
		@include bp(tablet-portrait-to-pc-portrait)
		{
			@include size-and-margin(25, 0.5);
		}
		
		@include bp(up-to-tablet-portrait)
		{
			@include size-and-margin(22, 0.5);
		}
	}
	
	h4
	{
		@include link-color(inherit, $color-orange);
		
		font-weight: normal;
		font-family: $font-heading;
		
		@include bp(above-pc-portrait)
		{
			@include size-and-margin(22);
		}
		
		@include bp(tablet-portrait-to-pc-portrait)
		{
			@include size-and-margin(21);
		}
		
		@include bp(up-to-tablet-portrait)
		{
			@include size-and-margin(20);
		}
		
		em
		{
			color: $color-orange;
			font-weight: bold;
			font-style: normal;
		}
	}
	
	h5
	{
		font-weight: normal;
		
		@include bp(above-pc-portrait)
		{
			@include size-and-margin(25);
		}
		
		@include bp(tablet-portrait-to-pc-portrait)
		{
			@include size-and-margin(23);
		}
		
		@include bp(up-to-tablet-portrait)
		{
			@include size-and-margin(21);
		}
	}
	
	h6
	{
		font-weight: 800;
		
		@include bp(above-pc-portrait)
		{
			@include size-and-margin(22);
		}
		
		@include bp(tablet-portrait-to-pc-portrait)
		{
			@include size-and-margin(21);
		}
		
		@include bp(up-to-tablet-portrait)
		{
			@include size-and-margin(20);
		}
	}
	
	hr
	{
		margin: 2em auto;
		border: 0;
		border-top: 1px solid rgba($color-gray, 0.75);
		display: block;
		height: 0;
		background: transparent;
		color: transparent;
	}
	
	img
	{
		$image-margin: $font-base-margin * $font-base-size;
		
		height: auto;
		max-width: 100%;
		
		&.alignleft,
		&.alignright
		{
			@include border-box();
			
			margin-top: round($image-margin * 0.25) + px;
			margin-bottom: $image-margin + px;
			display: block;
			max-width: 50%;
			
			@include bp(up-to-tablet)
			{
				&:not(.etf-always-float)
				{
					margin: 0 auto $image-margin + px;
					float: none;
					max-width: 100%;
				}
			}
		}
		
		&.alignleft
		{
			margin-right: $image-margin + px;
			float: left;
			clear: left;
		}
		
		&.alignright
		{
			margin-left: $image-margin + px;
			float: right;
			clear: right;
		}
		
		&.aligncenter
		{
			margin: 0 auto $image-margin + px;
			display: block;
			clear: both;
		}
	}
	
	li,
	p
	{
		line-height: $font-line-height-max + em;
	}
	
	small
	{
		font-size: 0.774em;
	}
}

@mixin fragile-elements()
{
	ol,
	ul
	{
		padding-left: ($font-base-margin * 1.25) + em;
		//overflow: hidden;
		
		&.etf-leaded-list
		{
			margin-bottom: 0.5em;
			
			> li
			{
				padding-bottom: 0.5em;
			}
			
			&:last-child
			{
				margin-bottom: 0;
				
				> li:last-child
				{
					padding-bottom: 0;
				}
			}
		}
		
		&.etf-spaced-list
		{
			margin-bottom: 0;
			
			> li
			{
				padding-bottom: 1em;
			}
			
			&:last-child > li:last-child
			{
				padding-bottom: 0;
			}
		}
		
		&.etf-two-columns
		{
			@include columns(2);
		}
	}
}
