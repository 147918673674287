#etf-banner
{
	background-image: url(../images/background-home.jpg);
	background-position: 20% 80%;
	
	> .etf-container
	{
		@include container-base(1.5, 1);
		
		h1
		{
			//text-transform: uppercase;
		}
	}
	.banner-list{
	  font-size: 1.4em;
	  li
	  {
	    margin-bottom: 20px;
	    font-weight: bold;
	  }
	  a
	  {
	    color: $color-white;
	    text-decoration: underline;
	    font-weight: bold;
	  }
	}
	.banner-pane{
    background-color: #1c2634;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 15px 20px 10px rgba(0,196,236,0.85);
	}
}

#etf-platform{
	.banner-pane{
    background-color: #1c2634;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 0 15px 20px 0 rgba(0,0,0,0.18);
	}
}
@include bp(above-pc)
{
  #etf-banner{
    min-height: 840px;
  }
}
#etf-banner-gray
{
	/*background-image: url(../images/background-home.jpg);*/
	background-position: 20% 80%;
	background-color: $color-light-gray;
	> .etf-container
	{

		@include container-base-thin(.5, .8);
		h1
		{
			//text-transform: uppercase;
			color: $color-slate;
		}
	}
}
#etf-banner-blue
{
	/*background-image: url(../images/background-home.jpg);*/
	background-position: 20% 80%;
	background-color: $color-dark-blue;
	> .etf-container
	{

		@include container-base-thin(.5, .8);
		h1
		{
			//text-transform: uppercase;
			color: $color-white;
		}
	}
}

#etf-banner-thin
{
	background-image: url(../images/background-home.jpg);
	background-position: 20% 65%;


	> .etf-container
	{
		@include container-base-thin(.5, .8);

		h1
		{
			/*text-transform: uppercase;*/
		}
	}
}

#etf-dash-banner-thin
{
	background-image: url(../images/background-home.jpg);
	background-position: 20% 65%;


	> .etf-container
	{
		@include container-base-thin(.5, .8);

		h1
		{
			/*text-transform: uppercase;*/
		}
	}
}

#etf-sponsor-banner-thin
{
	background-color: #d9d9d9;
	background-position: 20% 65%;


	> .etf-container
	{
		@include container-base-thin(.5, .8);

		h1
		{
			/*text-transform: uppercase;*/
		}
	}
}

