.etf-modal
{
	@include box-shadow(1px, 1px, 2px, 1px, rgba($color-black, 0.5));

    @-webkit-keyframes animatetop {
      from {top:-300px; opacity:0}
      to {top:0; opacity:1}
    }
    @keyframes animatetop {
      from {top:-300px; opacity:0}
      to {top:0; opacity:1}
    }
    .etf-sub-container.hide{
      display: none;
    }
    font-size: .8em;
    display: none;
    position: fixed;
    z-index: 10000;
    left: 0;
    top: 0;
    padding-top: 150px;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);

    .etf-row-form{
      &.active{
        background-color: #eee;
      }
    }

    .etf-modal-content{
      position: relative;
      background-color: #fefefe;
      margin: auto;
      padding: 0px;
      border: 1px solid #888;
      box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
      width: 50%;
      max-width: $width-pc-portrait;
      -webkit-animation-name: animatetop;
      -webkit-animation-duration: 0.4s;
      animation-name: animatetop;
      animation-duration: 0.4s;

      &.wide{
        width: 80%;
        max-width: 1200px;
      }
      .etf-modal-header{
        color: #fff;
        padding: 2px 16px;
        background-color: $color-dark-blue;
        h3{
          display: inline-block;
          padding-top: 5px;
        }
        .close {
          color: #fff;
          float: right;
          font-size: 34px;
          padding-top: 0px;
          font-weight: bold;
          cursor: pointer;
        }

      }
      .etf-modal-header-light{

        background-color: $color-light-gray;
        padding: 2px 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        h3{
          display: inline-block;
          margin: 0;
        }
        .modal-close {
          color: $color-dark-blue;
          font-size: 34px;
          cursor: pointer;
        }
      }
      .etf-modal-body{
        padding: 16px;




      }

    }
    .filter-form{
      padding-bottom: 10px;
      .multiselect{
        width:170px;
        display: inline-block;
        .selectBox{
          position: relative;
          select{
            width: 100%;
          }
          .overSelect{
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
          }
        }
        .f-checkboxes{
          display: none;
          position: absolute;
          padding: 5px;
          border: 1px solid #888;
          box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
          background-color: $color-white;
          z-index: 1000;
          label{
            display: block;
          }
          label:hover{
            background-color: $color-turquoise;
          }
        }
      }
    }


}


.etf-modal-new
{
	@include box-shadow(1px, 1px, 2px, 1px, rgba($color-black, 0.5));

    @-webkit-keyframes animatetop {
      from {top:-300px; opacity:0}
      to {top:0; opacity:1}
    }
    @keyframes animatetop {
      from {top:-300px; opacity:0}
      to {top:0; opacity:1}
    }
    .etf-sub-container.hide{
      display: none;
    }
    font-size: .8em;
    display: none;
    position: fixed;
    z-index: 10000;
    left: 0;
    top: 0;
    padding-top: 150px;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0,0,0);
    background-color: rgba(0,0,0,0.4);

    .etf-row-form{
      &.active{
        background-color: #eee;
      }
    }
    .etf-modal-content-new{
      position: relative;
      background-color: #fefefe;
      margin: auto;
      padding: 0px;
      border: 1px solid #888;
      box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
      width: 50%;
      max-width: 100%;
      -webkit-animation-name: animatetop;
      -webkit-animation-duration: 0.4s;
      animation-name: animatetop;
      animation-duration: 0.4s;
      //border-radius: 10px 10px 10px 10px;

      &.wide{
        width: 80%;
        max-width: 1200px;
      }
      .etf-modal-header{
        color: #fff;
        padding: 2px 16px;
        background-color: $color-dark-blue;
        h3{
          display: inline-block;
          padding-top: 5px;
        }
        .close {
          color: #fff;
          float: right;
          font-size: 34px;
          padding-top: 0px;
          font-weight: bold;
          cursor: pointer;
        }

      }
      .etf-modal-header-light{

        background-color: $color-light-gray;
        padding: 2px 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        h3{
          display: inline-block;
          margin: 0;
        }
        .modal-close {
          color: $color-dark-blue;
          font-size: 34px;
          cursor: pointer;
        }
      }
      .etf-modal-body-new{
        padding: 16px;
        border-radius: 50px 50px 50px 50px;


      }
      .etf-mu-button {

        flex: 1;
        padding: 10px;
        font-size: 16px;
        text-align: center;
        flex-grow: 1;
        margin-right: 10px;
      }

      .form-container {
          display: flex;
          flex-direction: column;
          gap: 10px;
      }

      .form-label {
          margin-bottom: 2px;
          font-size: 16px;
          color: #444;
          text-align: left;
      }

      .form-input {
          padding: 10px;
          margin-bottom: 15px;
          border: 1px solid #ccc;
          border-radius: 8px;
          font-size: 16px;
          width: 100%;
          box-sizing: border-box;
      }


    }
    .filter-form{
      padding-bottom: 10px;
      .multiselect{
        width:170px;
        display: inline-block;
        .selectBox{
          position: relative;
          select{
            width: 100%;
          }
          .overSelect{
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
          }
        }
        .f-checkboxes{
          display: none;
          position: absolute;
          padding: 5px;
          border: 1px solid #888;
          box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
          background-color: $color-white;
          z-index: 1000;
          label{
            display: block;
          }
          label:hover{
            background-color: $color-turquoise;
          }
        }
      }
    }


}

.etf-modal-footer-test {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}


@media (max-width: 600px) {
  .etf-modal-footer-test {
    flex-direction: column;
    align-items: stretch;
  }

  .etf-mu-button-test {
    margin-right: 0;
    margin-bottom: 10px;
  }
}



.etf-modal-footer-test2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.etf-mu-button-test2 {
  flex-grow: 1;
  margin-right: 10px;
  text-align: center;
}


.etf-mu-button:last-child {
  margin-right: 0;
}


@media (max-width: 600px) {
  .etf-modal-footer-test2 {
    flex-direction: column;
    align-items: stretch;
  }

  .etf-mu-button {
    margin-right: 0;
    margin-bottom: 10px;
  }

  .etf-mu-button:last-child {
    margin-bottom: 0;
  }
}



@media (max-width: 900px) {
  .etf-modal-body-new {
    //max-height: 400px;
    height: 400px;
    overflow-y: auto;
    padding-right: 10px;
    width: 90%;

  }
  .etf-modal-content-new {
      width: 90% !important;
      max-width: 100%;
      border-radius: 10px;
      margin: 0px;
      padding: 10px;
    }


  .form-container {
    padding: 10px;
  }


  .form-input {
    width: 100%;
  }
}

.file-upload-header {
//   text-align: left;
  margin-bottom: 20px;


  padding: 2px 16px 10px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
//   font-weight: bold;

  font-size: 20px;
  color: #1c3634;
  border-bottom: 2px solid lightgrey;

  .modal-close{
    color: #2e4057;
    font-size: 34px;
    cursor: pointer;
  }
}

.file-upload-header h2 {

  font-size: 20px;
  color: #1c3634;
  margin: 0;

//   padding-bottom: 10px;


}

// .file-upload-header {
//   text-align: left;
//   margin-bottom: 20px;
//
// //   padding: 2px 16px;
// //   display: flex;
// //   justify-content: space-between;
// //   align-items: center;
// }
//
// .file-upload-header h2 {
//   font-weight: bold;
//   font-size: 20px;
//   margin: 0;
//   padding-bottom: 10px;
//   border-bottom: 2px solid lightgrey;
//   color: #1c3634;
// }

.drop-zone {
  height: 150px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  cursor: pointer;
  color: #cccccc;
  border: 4px dashed #e6e6e6;
  border-radius: 10px;
}

.drop-zone--over {
  border-style: solid;
}

.drop-zone__input {
  display: none;
}
.drop-zone__icon {
  font-size: 40px;
  color: #cccccc;
  margin-bottom: 10px;
}

.drop-zone__thumb {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  background-color: #cccccc;
  background-size: cover;
  position: relative;
}

.drop-zone__thumb::after {
  content: attr(data-label);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 5px 0;
  color: #ffffff;
  background: rgba(0, 0, 0, 0.75);
  font-size: 14px;
  text-align: center;
}

