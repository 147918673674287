.etf-row
{
	margin: 0 -16px;

	&:first-child
	{
		margin-top: -16px;
	}

	&:last-child
	{
		margin-bottom: -16px;
	}
	
	&.etf-row-form
	{
		&:first-child
		{
			margin-top: -8px;
		}

		&:last-child
		{
			margin-bottom: -8px;
		}
    &.etf-row-form-dark
    {

      box-shadow: 0 15px 20px 10px rgba(0,196,236,0.85);
      border-radius: 10px;
      input,select{
        color: $color-dark-slate;
      }
    }
	}

}

[class^='etf-col-']
{
	@include border-box();

	padding: 16px;
	float: left;
	min-height: 1px;
	width: 100%;
	
	&.etf-col-right
	{
		float: right;
	}
	
	> hr
	{
		&:first-child
		{
			margin-top: 1em;
		}
		
		&:last-child
		{
			margin-bottom: 1em;
		}
	}
}

.etf-row-form > [class^='etf-col-']
{
	padding-top: 8px;
	padding-bottom: 8px;
	
	input[type='email'],
	input[type='tel'],
	input[type='text'],
	input[type='password'],
	select,
	textarea
	{
		&:not([size])
		{
			width: 100%;
		}
	}
}

@include bootstrap-columns(xs);

@include bp(up-to-phone)
{
	.etf-xs-hidden
	{
		display: none;
	}
    .sidebar-mobile
    {
      display: block;
    }
  .sidebar-sub-mobile
  {
    width: 100%;
  }
}

@include bp(above-phone)
{
	@include bootstrap-columns(sm);
	
	@include bp(up-to-tablet)
	{
		.etf-sm-hidden
		{
			display: none;
		}
    .sidebar-mobile
    {
      display: block;
    }
    .sidebar-sub-mobile
    {
      width: 100%;
    }
		.etf-sm-visible
		{
			display: block;
		}

	}
}

@include bp(above-tablet)
{
	@include bootstrap-columns(md);
	
	@include bp(up-to-pc-portrait)
	{
		.etf-md-hidden
		{
			display: none;
		}
    .sidebar-mobile
    {
      display: block;
    }
    .sidebar-sub-mobile
    {
      width: 80%;
    }
		.etf-md-visible
		{
			display: block;
		}
	}
}

@include bp(above-pc-portrait)
{
	@include bootstrap-columns(lg);
	
	@include bp(up-to-site)
	{
		.etf-lg-hidden
		{
			display: none;
		}
    .sidebar-mobile
    {
      display: block;
      position: relative;
      width: auto;
    }
    .sidebar-sub-mobile
    {
      width: 15%;
    }
		.etf-lg-visible
		{
			display: block;
		}
	}
}

@include bp(above-site)
{
	@include bootstrap-columns(xl);
	
	.etf-xl-hidden
	{
		display: none;
	}
	
	.etf-xl-visible
	{
		display: block;
	}
}
