
#research-subscribe
{
	.etf-field:not(:last-child)
	{
		margin-bottom: 0.5em;

		input
		{
			background: none;

			border-color: $color-white;
		}
		input::placeholder {
            color: #1c2634;
        }
	}
}
