


.accordion
{
  border-radius: 0px 0px 10px 10px;
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.25);
  background-color: $color-white;
  color: $color-white;

  [class^="etf-col-"]{
    padding: 0px;
  }

  .head-cont{
    background-color: $color-light-gray;
    color: $color-dark-blue;
    box-shadow: -2px 0px 4px 0px rgba(0,0,0,0.25);
    border-radius: 10px 0px 0px 10px;
  }

  .acc-head{
    padding: 20px;



    &:hover{
      color: $color-orange;
      cursor: pointer;
    }

    &.active{
      background-color: $color-dark-blue;
      color: $color-white;
    }

  }

  .acc-body
  {
    padding: 20px;
    display: none;
    color: $color-dark-blue;
    height: 100%;
    &.active
    {
      display: block;

    }
  }


}