//Solid
$color-black: #000;
$color-blue: #006b96;
$color-dark-blue: #2e4057;
$color-dark-slate: #1c2634;
$color-extra-light-gray: #c3c0c0;
$color-facebook: #537bbd;
$color-gray: #8c96a3;
$color-green: #008000;
$color-light-gray: #eaecee;
$color-light-slate: #515e70;
$color-link: #5f90af;
$color-linkedin: #2085c7;
$color-logo: #13123e;
$color-orange: #f18f01;
$color-red: #c00;
$color-slate: #333c49;
$color-turquoise: #00c4ec;
$color-twitter: #78cdf0;
$color-white: #fff;

// Calculated
$color-dark-orange: mix($color-orange, $color-black, 75%);
$color-dark-green: mix($color-green, $color-black, 75%);
$color-light-orange: mix($color-orange, $color-white, 75%);
$color-light-green: mix($color-green, $color-white, 75%);
$color-half-gray: mix($color-black, $color-white, 50%);
$color-quarter-gray: mix($color-black, $color-white, 25%);
$color-shadow: rgba($color-black, 0.125);
