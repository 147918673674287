#etf-hero
{
	background-image: url(../images/background-home.jpg);
	background-position: center bottom;
	left: 0;
	min-width: $width-min;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 1;
	
	> .etf-container
	{
		@include container-base(1.25, 3.5);
	}
}
