
.content-no-header
{
  .etf-dark-blue-section
  {
    background: #fff none no-repeat center center;
    background-size: cover;
    position: relative;
    z-index: 98;
    background-color: #2e4057;
    color: #fff;
    .etf-container
    {
      padding: 20px 0px 10px 0px;;
    }
    #earnings-summary
    {
      display: flex;
      font-size: 12px;
      color: $color-white;
      height: 65px;
      margin: 20px 0px 0px 0px;
      .summary-item{
        padding: 10px;
      }
    }
  }
}

.etf-tab{
  display: none;
}
.ho-chart-toggle{
  padding: 7px;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  background: rgba(208,207,207,0.8);
  color: #383838;
  border-radius: 22px;
  cursor: pointer;
  &.active{
    background: #f18f01;
    color: #fff;
    border-radius: 22px;
  }
}
.ho-fp-fund-chart-toggle{
  padding: 7px;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  background: rgba(208,207,207,0.8);
  color: #383838;
  border-radius: 22px;
  cursor: pointer;
  &.active{
    background: #f18f01;
    color: #fff;
    border-radius: 22px;
  }
}
.data-switch-toggle {
   float: left;
   background: rgba(208, 207, 207, 0.8);
   border-radius: 22px;
}
.data-switch-toggle input {
  position: absolute;
  opacity: 0;
}
.data-switch-toggle input + label {
  padding: 7px;
  float:left;
  color: #383838;
  cursor: pointer;
}
.data-switch-toggle input:checked + label {
  background: #f18f01;
  color: #fff;
  border-radius: 22px;
}

 .data-switch-toggle-new {
   float: left;
   background: rgba(208, 207, 207, 0.8);
   border-radius: 22px;
}
.data-switch-toggle-new input {
  position: absolute;
  opacity: 0;
}
.data-switch-toggle-new input + label {
  padding: 5px;
  float:left;
  color: #383838;
  cursor: pointer;
}
.data-switch-toggle-new input:checked + label {
  background: $color-dark-blue;
  color: #fff;
  border-radius: 22px;
}

.content-editor{
    box-shadow: -2px 2px 5px 4px rgba(0,196,236,0.85);
    padding: 15px;
    margin: 30px 0px 30px 0px;
    cursor: pointer;

    -webkit-transition: all .5s ease;
    -moz-transition: all .5s ease;
    -o-transition: all .5s ease;
    -ms-transition: all .5s ease;
    transition: all .5s ease;
    &:hover{
      background-color:rgba(0, 0, 0, 0.1);
    }
}
.img-up-container{
  text-align: center;
  vertical-align: middle;
  margin: 20px 0px 20px 0px;
  .image-upload{
      position: relative;
      text-align: center;
      display: inline-block;
      cursor: pointer;
      width: 300px;
      max-height: 300px;
      -webkit-filter: brightness(100%);

      -webkit-transition: all .5s ease;
      -moz-transition: all .5s ease;
      -o-transition: all .5s ease;
      -ms-transition: all .5s ease;
      transition: all .5s ease;
      &:hover{
        -webkit-filter: brightness(80%);
      }
      &.image-upload-circle{
        border-radius: 50%;
      }
      .file-btn{
        position: absolute;
        width: 300px;
        height: 100%;
        z-index: 1000;
        &.file-btn-circle{
          border-radius: 50%;
        }
      }
      &img{
        max-height: 300px;
        max-width: 300px;
      }
  }
}

.branding-img{
    box-shadow: -2px 2px 5px 4px rgba(0,196,236,0.85);
    &.img-circle{
      border-radius: 50%;
    }
}
.table-header {
  border: 1px solid silver;
  text-align: center;
  height: 80px;
}
.table-data {
  border: 1px solid silver;
  text-align: center;
  font-size: .9em;


}

.t-head-grid, .t-body-grid {
    border: 1px solid black;
    padding: 8px;
    text-align: center;
}
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}
.switch-perf {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
}


.plan-table{
  width: 100%;
  border: 1px solid black;
  border-collapse: collapse;
}
.file-btn input[type="file"] {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
}
.file-btn.file-btn-circle input[type="file"] {
  border-radius: 50%;
}
input[type="file"] {
    cursor: pointer;
}
.content-page{
  .page{
    border: 1px solid black;
  }
}


.t-wrapper-sm.h-scroll{
  overflow-x: auto;
  white-space: nowrap;
  scrollbar-width: thin;
  .t-body{
    overflow-x: auto;
  }
}
.t-wrapper-sm.h-scroll::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background-color: #00c4ec;
}
.t-wrapper-sm.h-scroll::-webkit-scrollbar-track {
  background-color: #FFFFFF;
}
.t-wrapper-sm.h-scroll::-webkit-scrollbar-thumb {
  background-color: #00c4ec;
}
.t-wrapper-sm.h-scroll::-webkit-scrollbar-thumb:hover {
  background-color: #00c4ec;
}
@media (max-width: 768px) {
  .t-wrapper-sm.h-scroll {
    overflow-x: scroll;
    white-space: nowrap;
  }
}
.hp-wrapper{
    display: flex;
    align-items: center;
    position: relative;
    background-color: #eaecee;
    padding-left: 30px;
    padding-right: 35px;
    .hp-scroll-btn {
        border: none;
        background-color: transparent;
        color: #1c2634;
        padding: 10px;
        font-size: 1.8em;
        cursor: pointer;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        display:none;
    }

    .left-btn {
        left: 0;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    .right-btn {
        right: 0;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }

    .hp-container {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;

        scrollbar-width: thin;
        overflow-x: auto;
        scroll-behavior: smooth;

        width: 100vw;
        .rep-container{
          flex: 1;
           display: flex;
            align-items: center;
            justify-content: center;
          max-width: 135px;
          min-width: 135px;
          border: 1px solid white;
          box-shadow: 1px 1px 3px 2px lightblue;
          border-radius: 25px;
          margin: 10px;
          text-align: center;
          color: black;
          &:hover{
            background-color: rgba(0,196,236,0.85);
            box-shadow: 0 10px 15px 0 rgba(0,196,236,0.85)
          }
        }

//         @media screen and (max-width: 640px){
//           .container{
//             display: block;
//             width: 100%;
//           }
//         }

        .polaroid {
          width:100%;
          height: 100px;
          background-color: white;
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0),0 6px 20px 0 rgba(0, 0, 0, 0.1);
          margin-bottom: 26px;
          border-bottom: 1px solid $color-slate;
        }
        .content {
          position: relative;
           width: 100%;
           cursor: pointer;
        //   width: 200px;
          margin: auto;
          overflow: hidden;
          border-radius: 25px;
          .text{
            background-color: white;
            font-size: .8em;
            height: 30px;
            bottom: 0px;
          }
        }
        .content .content-overlay {
          background: rgba(0,0,0,0.7);
          position: absolute;
          height: 75%;
          width: 100%;
          left: 0;
          top: 0;
          bottom: 0;
          right: 0;
          opacity: 0;
          -webkit-transition: all 0.4s ease-in-out 0s;
          -moz-transition: all 0.4s ease-in-out 0s;
          transition: all 0.4s ease-in-out 0s;
        }
        .content:hover .content-overlay{
          opacity: 1;
        }
        .content-image{
          width: 100%;
        }
        .content-details {
          position: absolute;
          text-align: center;
          padding-left: 1em;
          padding-right: 1em;
          width: 100%;
          top: 50%;
          left: 50%;
          opacity: 0;
          -webkit-transform: translate(-50%, -50%);
          -moz-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          -webkit-transition: all 0.3s ease-in-out 0s;
          -moz-transition: all 0.3s ease-in-out 0s;
          transition: all 0.3s ease-in-out 0s;
        }
        .content:hover .content-details{
          top: 50%;
          left: 50%;
          opacity: 1;
        }

        .content-details h3{
          color: #fff;
          font-weight: 500;
          letter-spacing: 0.15em;
          margin-bottom: .5em;
          text-transform: uppercase;
        }
        .content-details p{
          color: #fff;
          font-size: 1.2em;
          margin-bottom: 3em;
        }
        .btn-icon-lg {
          flex: 1;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 15px 15px 15px 15px;
          max-width: 120px; /* Adjust the maximum width as needed */
          min-width: 120px;

          padding: 0px;
          margin: 10px;
          &:hover{
            background-color: rgba(0,196,236,0.85);
            box-shadow: 0 15px 20px 0 rgba(0,196,236,0.85)
          }
          background-color: rgba(0,196,236,0.85);
          img{
            width: 100%;
          }
        }

    }
}


.t-body1 {
  display: flex;
  justify-content: space-around;
  padding: 15px;
  background-color: #eaecee;
}



@media (max-width: 768px) {
  .t-body1 {
    flex-wrap: wrap;
  }
  .btn-icon-lg {
    flex: 0 0 80%; /* Adjust the flex basis as needed */
    max-width: 100px;
  }
}

.etf-section,.etf-section-gated
{
  .t-wrapper-sm{
    input,select{
      color: $color-dark-blue;
    }
  }
  .t-wrapper{
    border-radius: 10px;

    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.25);
    .t-head{
      border-radius: 10px 10px 0px 0px;
      padding: 8px;


      position: relative;
      color: $color-white;
      background-color: $color-dark-blue;
      input,select{
        color: $color-dark-blue;
      }
      .t-head-name{
        font-size: 20px;
      }
      .table-toggle{
        font-size: 18px;
        &:hover{
          cursor: pointer;
          text-decoration: underline;
        }
      }
      .etf-head-nav{
        > li{
          display: inline;
        }
        float: right;
        margin-bottom: 0px;
      }

    }
    .t-head2{
          border-radius: 10px 10px 0px 0px;
          padding: 8px;
          position: relative;
          color: $color-white;
          background-color: #3d7de3;
          input,select{
            color: $color-dark-blue;
          }
          .t-head-name{
            font-size: 20px;
          }
          .table-toggle{
            font-size: 18px;
            &:hover{
              cursor: pointer;
              text-decoration: underline;
            }
          }
          .etf-head-nav{
            > li{
              display: inline;
            }
            float: right;
            margin-bottom: 0px;
          }

        }
    .t-summary{
      .summary-bar
      {
        display: flex;
        font-size: 12px;
        height: 65px;

        box-shadow: 0 0 5px #bbb;
        .summary-item
        {
          padding: 10px;
        }
        @include bp(up-to-tablet)
        {
          height: 100px;
          overflow-x: scroll;
          overflow-y: hidden;
          -webkit-overflow-scrolling: touch;
        }

      }

    }

    .t-body{
      padding: 8px;
      min-height: 500px;
      overflow: scroll;
    }
     .t-foot{
      border-radius: 0px 0px 10px 10px;
      padding: 8px;

      background-color: $color-light-gray;
    }
  }

 .t-wrapper-sm{
    border-radius: 10px;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.25);
    .t-head{
      border-radius: 10px 10px 0px 0px;
      padding: 4px;
//       min-height: 30px;
      position: relative;
      color: $color-white;
      background-color: $color-dark-blue;

    }
    .t-head{
      .etf-head-nav{
        > li{
          display: inline;
        }
        float: right;
        margin-bottom: 0px;
      }
    }
    .ticker-market-table {
      display: block;
      width: 100%;
    }
    .ticker-head{
      border-radius: 10px 10px 0px 0px;
      padding: 8px;
      height: 10px;

      position: relative;
      color: $color-white;
      background-color: $color-dark-blue;

    }
    .ticker-head{
      .etf-head-nav{
        > li{
          display: inline;
        }
        float: right;
        margin-bottom: 0px;
      }
    }
    .ticker-elem2 {
      display: inline-block;
    }
    .ticker-data div {
      position: absolute;
    }
    .ticker-data {
      display: block;
      float: left;
      border: 1px solid black;
      width: 10%;
      padding-bottom: 32%;
    }
    .ticker-labels {
      width:100%;
      text-align:center;
    }
    .ticker-labels div{
      width: calc(100% / 10);
      display: inline-block;
      vertical-align: top;
      //border:1px solid red;
      text-align:center;
      //border-right: 2px solid black;
      font-size: 12px;
      margin-left:-4px;
      padding:0px;
    }
    .t-head2{
      //display: flex;
      //flex-direction: column;
      //overflow: auto;
      height: 50px;

    }
    .grid-body {
        display: grid;
        grid-template-columns:auto auto auto auto auto auto auto auto auto auto;
        //row-gap: 0.1rem;
        //column-gap: 0.5rem;
        //height: 8rem;
        //margin: 0.5rem;
        align-items: center;
        border: 1px solid rgba(255, 255, 255, 0.8);
        //overflow: auto;
    }
    .grid-header {
        display: contents;

    }
    .ticker-span{
      display: block;
    }
    .grid-content {
        display: contents;
    }

    .grid-header label {
        position: sticky;
        top:0%;
        vertical-align: middle;
        text-align: center;
        height: 20px;
        color: $color-white;
        background-color: $color-dark-blue;
        //border-bottom: solid 1px black;
    }
    .grid-content label {
        display: flex;
        //text-align: center;
        align-items: center;
        justify-content: center;

        height: 30px;
        //display: flex;
        //vertical-align: middle;
        //color: $color-white;
        background-color: $color-white;
        }
    .grid-container{
          display: grid;
          grid-template-columns: auto auto auto;
          //border-radius: 10px 10px 0px 0px;
          padding: 8px;
          //min-height: 30px;

          //position: relative;
          color: $color-white;
          background-color: $color-dark-blue;


        }
    .grid-item {
      background-color: rgba(255, 255, 255, 0.8);
      border: 1px solid rgba(0, 0, 0, 0.8);
      padding: 20px;
      font-size: 30px;
      text-align: center;
    }
    .t-body{
      padding: 8px;
      overflow-x: auto;

    }
    .t-body-no-scroll{
      padding: 8px;
      /* overflow-x: visible; */

    }
    .t-foot{
      border-radius: 0px 0px 10px 10px;
      padding: 8px;

      background-color: $color-light-gray;
    }
  }
  .c-wrapper{
    border-radius: 10px;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.25);
    .c-head{
      border-radius: 10px 10px 0px 0px;
      padding: 8px;
      position: relative;
      color: $color-white;
      background-color: $color-dark-blue;
      input,select{
        color: $color-dark-blue;
      }
    }
    .c-body{
      padding: 8px;
      min-height: 500px;
    }
    .c-foot{
      border-radius: 0px 0px 10px 10px;
      padding: 8px;

      background-color: $color-light-gray;
    }

  }
}

.popup {
  display: none;
  position: fixed;
  padding: 10px;
  width: 800px;
  left: 50%;
  margin-left: -300px;
  height: 590px;
  top: 50%;
  margin-top: -200px;
  background-color: #ffffff !important;
  opacity: 1 !important;
  z-index: 10000 !important;
  mix-blend-mode: normal !important;
}

#overlay {
  position: fixed;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0,0,0,0.5);
  z-index: -2;
}

.popup-dash {
  display: none;
  position: fixed;
  padding: 10px;
  width: 800px;
  left: 50%;
  margin-left: -300px;
  height: 130px;
  top: 50%;
  margin-top: -200px;
  background: #FFF;
  z-index: 20;
}

.popup-ss {
  display: none;
  position: fixed;
  padding: 10px;
  width: 900px;
  left: 50%;
  margin-left: -300px;
  height: auto;

  top: 50%;
  margin-top: -200px;
  background: #FFF;
  z-index: 20;
}
.popup-load {
  display: none;
  position: fixed;
  padding: 10px;
  width: 900px;
  left: 50%;
  margin-left: -300px;
  height: auto;

  top: 50%;
  margin-top: -200px;
  background: #FFF;
  z-index: 20;
}

#loading-popup:after {
  position: fixed;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0,0,0,0.5);
  z-index: -2;
}

#loading-popup:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #FFF;
  z-index: -1;
}


#screenshot-popup:after {
  position: fixed;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0,0,0,0.5);
  z-index: -2;
}

#screenshot-popup:before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #FFF;
  z-index: -1;
}

.popup-upgrade {
  display: none;
  position: fixed;
  padding: 10px;
  width: 400px;
  left: 50%;
  margin-left: -100px;
  height: 100px;
  top: 50%;
  margin-top: -100px;
  background: #FFF;
  z-index: 20;
}
 #popup-fund:after,#screens-editor-popup,#screens-dash-popup:after,#dash-editor-popup:after {
  position: fixed;
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0,0,0,0.5);
  z-index: -2;
}



// #popup-fund:before,#screens-popup:before,#screens-editor-popup,#screens-dash-popup:before,#dash-editor-popup:before {
//
//   position: absolute;
//   content: "";
//   top: 0;
//   left: 0;
//   bottom: 0;
//   right: 0;
//   background: #FFF;
//   z-index: -1;
// }
#searchInput {
//     width: 100%;

//     padding: 10px;
//     margin-bottom: 10px;
//     box-sizing: border-box;
}


#ir-select-wrapper {
    position: relative;
    display: inline-block;
}
.custom-select {
    //position: absolute;

    top: 100%;
    left: 0;
    right: 0;
    max-height: 200px;
    overflow-y: auto;
    border: 1px solid #ccc;
    background-color: white;
    z-index: 10000000;
    display: none; /* Hide by default */

}

.custom-option {
    padding: 8px;
    cursor: pointer;
}

.custom-option:hover {
    background-color: #ddd;
}


#the-count {
  float: right;
  padding: 0.1rem 0 0 0;
  font-size: 0.875rem;
}
#myImg:hover {opacity: 0.7;}

/* The Modal (background) */
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0,0.9); /* Black w/ opacity */
}

/* Modal Content (image) */
.modal-content {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
}

/* Caption of Modal Image */
#caption {
  margin: auto;
  display: block;
  width: 80%;
  max-width: 700px;
  text-align: center;
  color: #ccc;
  padding: 10px 0;
  height: 150px;
}

/* Add Animation */
.modal-content, #caption {
  -webkit-animation-name: zoom;
  -webkit-animation-duration: 0.6s;
  animation-name: zoom;
  animation-duration: 0.6s;
}

@-webkit-keyframes zoom {
  from {-webkit-transform:scale(0)}
  to {-webkit-transform:scale(1)}
}

@keyframes zoom {
  from {transform:scale(0)}
  to {transform:scale(1)}
}

/* The Close Button */
.close {
  position: absolute;
//   top: 15px;
  right: 35px;
  color: #f1f1f1;
  font-size: 40px;
  font-weight: bold;
  transition: 0.3s;
}

.close:hover,
.close:focus {
  color: #bbb;
  text-decoration: none;
  cursor: pointer;
}

/* 100% Image Width on Smaller Screens */
@media only screen and (max-width: 700px){
  .modal-content {
    width: 100%;
  }
}

.t-summary{
  .summary-bar
  {
    display: flex;
    font-size: 12px;
    height: 65px;

    box-shadow: 0 0 5px #bbb;
    .summary-item
    {
      padding: 10px;
    }
    @include bp(up-to-tablet)
    {
      height: 100px;
      overflow-x: scroll;
      overflow-y: hidden;
      -webkit-overflow-scrolling: touch;
    }

  }

}
.callout{
    box-shadow: 0 0 5px #bbb;
    padding: 10px;
    font-size: 1.2em;
}

.pos-value{
  color: green;
  filter: brightness(1.3);
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}
.pos-sum-value{
  color: green;
  font-weight: bold;
}
.neg-value{
  color: red;
  filter: brightness(1.3);
  -webkit-animation-duration: 2s;
  animation-duration: 2s;
}
.neg-sum-value{
  color: red;
  font-weight: bold;
}
  .etf-autocomplete {
    position: relative;

    display: inline-block;
    width: 110px;
    input{
      text-align: center;
      width: 110px;
    }
  }
.etf-autocomplete-new {
    position: relative;
    display: inline-block;
    width: auto;
    padding: 0px;
    input{
      text-align: center;
      width: 110px;
      border: 3px solid #f18f01;
      width: 90px;
    }
  }

.etf-autocomplete-items {
  position: absolute;
  border: 1px solid #d4d4d4;
  border-bottom: none;
  border-top: none;
  z-index: 999;
  top: 100%;
  left: 0;
  right: 0;
  width: 300px;
  max-height: 400px;
  overflow-y: scroll;
  border: 1px solid #141140;
}
.etf-autocomplete-items div {
  padding: 10px;
  cursor: pointer;
  background-color: #fff;
  border-bottom: 1px solid #d4d4d4;
}
.etf-autocomplete-items div:hover {
  background-color: #e9e9e9;
}
.autocomplete-active {
  background-color: DodgerBlue !important;
  color: #ffffff;
}
.summary-bar{
  .summary-item{
    display: inline-block;
    margin-right: 10px;
  }
}
.disclosure{
  font-size: 10px;
  margin: 8px;
}
.mm-disclosure{
  font-style: italic;
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.25);
  background-color: #dedede;
  overflow-x: hidden;
  overflow-y: auto;
  min-height: 100px;
  max-height: 200px;
  padding: 10px;
  &.mm-disclosure-sm{
    min-height: 10px;
    max-height: 50px;
  }
}
.float-right{
  float: right;
  margin-left: 10px;
}
.grid-cell-centered {
  text-align: center;
  justify-content: center;
}
.grid-cell-right {
  text-align: right;
  justify-content: right;
}
.etf-section-gated,.etf-modal
{
  .ev-launch{
    color: $color-orange;
    &:hover{


    }
  }
  .summary-bar{
    .summary-item{
      display: inline-block;
      margin-right: 10px;
    }
  }
  .summary-bar-sm{
    .summary-item{
      display: inline-block;
      margin-right: 10px;
    }
  }
  .disclosure{
    font-size: 10px;
    margin: 8px;
  }
  .float-right{
    float: right;
    margin-left: 10px;
  }
  .overlap-table{
    max-width: 100%;
    border-collapse: collapse;

    td{
      text-align: center;
      padding: 5px;
      max-width: 100px;
      min-width: 75px;
      height: 30px;
      border: 1px solid $color-light-gray;
      border-bottom: 1px solid $color-turquoise;
    }
    .t-header{
      font-weight: bold;
      text-align: left;
      &.td-center{
        text-align: center;
      }
    }
    .td-filler{
      background-color: $color-light-gray;
    }
  }
  .correlation-table{
      max-width: 100%;
      border-collapse: collapse;

      td{
        text-align: center;
        padding: 5px;
        max-width: 100px;
        min-width: 75px;
        height: 30px;
        border: 1px solid $color-light-gray;
        border-bottom: 1px solid $color-turquoise;
      }
      .t-header{
        font-weight: bold;
        text-align: left;
        &.td-center{
          text-align: center;
        }
      }
      .td-filler{
        background-color: $color-light-gray;
      }
    }
  .chip {
      display: inline-block;
      padding: 0 25px;
      margin-top: 5px;
      margin-bottom: 5px;
      margin-left: 10px;
      height: 30px;
      font-size: 13.5px;
      font-family: "Muli",sans-serif;
      text-align: center;
      line-height: 30px;
      border-radius: 25px;
      background-color: #51647d;
      color: white;
      width: 66px;
  }
  .button-hover {
        margin-top: 5px;
        margin-bottom: 5px;
        transition: background 125ms ease-in-out;
        margin-left: 10px;
        height: 40px;
        width: 1px;
  }
  .button-hover:hover {
        background-color:#002ead;
        transition: 0.7s;
    }
  .closebtn {
    padding-left: 5px;
    color: #c5c7c9;
    font-weight: bold;
    float: right;
    font-size: 20px;
    cursor: pointer;
  }

  .closebtn:hover {
    color: #000;
  }
  .closebtn-screens {
      padding-left: 5px;
      color: #c5c7c9;
      font-weight: bold;
      float: right;
      font-size: 20px;
      cursor: pointer;
    }

    .closebtn-screens:hover {
      color: #000;
    }
  .date-picker {
    border: 3px solid #f18f01;
    width: 90px;
  }



  .green-background {
    background-color: green;
    color: white;
  }
  .red-background {
    background-color: red;
    color: white;
  }
  .grey-background {
      background-color: #eaecee;
      color: black;
    }

  .cell-wrap-text {
    white-space: normal !important;
  }


//   .overlap-ag-grid{
    .grid-cell-centered {
      text-align: center;
    }
    .cell-wrap-text {
      white-space: normal !important;
    }
    .ag-theme-alpine .ag-cell-value {
      line-height: 20px !important;
      word-break: normal;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 5px;
      padding-right: 5px;
    }
    .ag-theme-balham .ag-cell-value {
      line-height: 20px !important;
      word-break: normal;
      padding-top: 5px;
      padding-bottom: 5px;
      padding-left: 5px;
      padding-right: 5px;
    }
    .ag-header-cell-label {
       justify-content: center;
    }
    .ag-row .ag-cell {
      display: flex;
  //     justify-content: center;
      align-items: center;
      --ag-value-change-delta-up-color: green;
      --ag-value-change-delta-down-color: red;
    }

    .ag-header-cell-label .ag-header-icon.ag-sort-order {
      display: none;
    }
//   }


  .ag-pos-value {
    --ag-data-color: rgb(0, 255, 8);
  }
  .ag-neg-value {
    --ag-data-color: rgb(255, 17, 0);
  }
  .ag-highlighted {
    background-color: #c7c6c3 !important;
  }
  .ag-neg-highlighted {
    background-color: #ff5959 !important;

  }

  #result {
      text-align: right;
      color: gray;
      min-height: 2em;
  }

  #table-sparkline {
      margin: 0 auto;
      border-collapse: collapse;
  }

  th {
      font-weight: bold;
      text-align: left;
  }

  td,
  th {
      padding: 5px;
      border-bottom: 1px solid silver;
      height: 20px;
  }

  thead th {
      border-top: 2px solid gray;
      border-bottom: 2px solid gray;
  }

  .highcharts-tooltip > span {
      background: white;
      border: 1px solid silver;
      border-radius: 3px;
      box-shadow: 1px 1px 2px #888;
      padding: 8px;
  }



  margin-top: 65px;

   @keyframes pos-flash {
      0%{
         background-color: green;
         color: white;
         font-weight: bold;
      }
   }
  .pos-flash{
    animation-name: pos-flash;
  }
   @keyframes neg-flash {
      0%{
         background-color: red;
         color: white;
         font-weight: bold;
      }
   }
  .neg-flash{
    animation-name: neg-flash;
  }
  .screener-sel-group{
    display: inline-block;
  }
  .screener-select{
    margin-right: 20px;
    border: 3px solid #f18f01;

    &-dark{
      background: none;
      color: #fff;
    }
  }
  .terminal-container{
    padding-left: 16px;
    padding-right: 12px;
    display: none;
    @include bp(up-to-pc-portrait)
    {
      padding-left: 28px;
      padding-right: 12px;
    }
    &.active{
      display: block;
    }
  }
  .block-right{

		@include bp(above-tablet)
		{
			float: right;
		}
	}

  &.holdings-analyzer
  {
    .dataTables_wrapper{
      font-size: 12px;
    }
    table
    {
      -webkit-user-select: none;
      -webkit-touch-callout: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
  }


  &.etf-terminal
  {
    font-size: .8em;
    .dataTables_wrapper
    {
      font-size: 12px;
    }

//     .etf-terminal-nav,.etf-mm-nav,.etf-oefs-nav,.etf-etfs-nav{
//       box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.25);
//       font-size: 1.1em;
//       .li-right{
//         @include bp(above-tablet)
//         {
//           float: right;
//         }
//
//       }
//     }
    .etf-terminal-nav-dark{
      box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.25);
      font-size: 1.3em;
      background-color: $color-dark-blue;
      color: $color-white;
      .li-right{
        @include bp(above-tablet)
        {
          float: right;
        }
      }
    }

    .etf-terminal-main,.etf-content-main,etf-mm-main,.etf-mMkt-main{
      display: none;
      box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.25);

      .etf-item{
        padding: 5px;
        border-bottom: 1px solid $color-light-gray;
        label{
          color: $color-slate;
          font-weight: bold;

          font-size: .9em;
        }
        p{
          font-size: 1.2em;
          margin-top: 5px;
          margin-bottom: 0px;
        }
      }
      &.active{
        display: block;
      }
      .etf-res-left{
        height: 100%;
        box-shadow: 2px 0px 0px 0px rgba(0,0,0,0.25);
      }

    }
    .etf-header-new {
//       overflow-x: visible;
//       overflow-y: visible;
      max-width: 100%;
      white-space: nowrap;
      scrollbar-width: thin;
      padding: 5px;
      margin: 0px;
      border: 1px solid #bdc3c7;
      background-color: #f5f7f7;

      .ss-table-toggle{
        font-size: 18px;
        &:hover{
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
    .tab-container {
      display: flex;
      justify-content: flex-start;
      border-bottom: thin solid $color-gray;
      margin-bottom: 5px;
    }

    /* Individual tab styling */
    .tab {
      cursor: pointer;
      padding: 10px 20px;
      transition: background-color 0.3s ease;

//       background-color: #3498db;
//       color: #fff;
//       border-radius: 5px;
      &.active{
        border-bottom: solid #515e70;
        font-weight: bold;
      }
    }

    .tab:hover {
      background-color: #e6e6e6;
    }
    #etf-research-container
    {
      .standard-perf{
        margin: auto;
        tr td{
          border-bottom: 1px solid LightGray;
          vertical-align: bottom;
          padding: 5px;
        }
        tr th{
          border-bottom: 1px solid $color-dark-slate;
          padding: 5px;
        }
        .col-label{
          text-align: left;
          .perf-val {
              margin: 0px;
              display: inline-block;
              text-align: left;
          }
        }
      }

    }
    #etf-terminal-model,#etf-model-builder,#etf-model-market,#etf-oefs-terminal
    {
      #mod-table{
        font-size: 12px;

      }

      .etf-head-nav{
        > li{
          display: inline;
        }
        float: right;
        .s-link{
          color: #f18f01;
          font-size: 16px;
          font-weight: bold;
          cursor: pointer;
          margin-top: 12px;
        }
        .s-link:hover{
          text-decoration: underline;
        }
        .s-link i{
          font-size: 12px;
          margin-left: 3px;
        }
      }
      .etf-head-nav-left{
        > li{
          display: inline;
        }
        .s-link{
          color: #f18f01;
          font-size: 16px;
          font-weight: bold;
          cursor: pointer;
          margin-top: 12px;
        }
        .s-link:hover{
          text-decoration: underline;
        }
        .s-link i{
          font-size: 12px;
          margin-left: 5px;
        }
      }

      .etf-model-content{
        min-height: 550px;
        display: none;
        &.active{
          display: block;
        }
      }
      #etf-model-fund-list{
        box-shadow: 0 2px 4px 0 rgba(0,0,0,0.125);
      }
      .holding-wt{
        width: 60px;
        text-align: right;
      }
      td.remove-fund {
        /*background: url('/assets/images/details_close.png') no-repeat center center;*/
        cursor: pointer;
      }
      .benchmark-cont{
        color: #fff;
        font-size: 1.3em;
        padding: 10px;
        margin-right: 10px;
      }
      #bench{
        cursor: pointer;
      }
      #bench:hover{
        text-decoration: underline;
      }
      #model-name{
        cursor: pointer;
      }
      #model-name:hover{
        text-decoration: underline;
      }
      .update-wt{
        background-color: green;
        cursor: pointer;
        border-radius: 5px;
        width: 40px;
        box-shadow: 0 0 3px #bbb;
      }
      .remove-fund{
        width: 60px;
        cursor: pointer;
        text-align: center;
        /*border-radius: 5px;
        box-shadow: 0 0 3px #bbb;*/
      }
      .update-wt .fa-check{
        color: #fff;
        margin-right: 0px;
      }
      .remove-fund .fa-times{
        color: #fff;
        margin-right: 0px;
      }
      .alloc-row{

        font-weight: bold;
        text-shadow: white 0px 0px 10px;

      }
      .alloc-table{
        box-shadow: 0 0 3px #bbb;
        th{
          font-weight: bold;
        }
        td{
          border-bottom: 1px solid $color-turquoise;
        }
      }
      td.alloc-tr{
        cursor: pointer;
      }
      td.alloc-tr:hover{
        text-decoration: underline;
      }
    }
    .chart{
      margin: 0 auto;
    }
  }

  .etf-row.etf-row-head
  {
    color: $color-white;
    font-size: 16px;
    background-color: $color-blue;
    .orange
    {
      color: $color-light-orange;
    }
    .live-stat-container{
      //display: none;
      padding-bottom: 0px;
    }
    .etf-live-stat,.etf-model-stat{
      padding-right: 20px;
      font-size: 1.1em;
      font-weight: normal;
      text-shadow: rgb(28, 38, 52) -1px 0px 1px, rgb(28, 38, 52) 0px 1px, rgb(28, 38, 52) 1px 0px, rgb(28, 38, 52) 0px -1px;
      &.off-weight{
        color: red;
        font-weight: bold;
        text-shadow: white 0px 0px 10px;
      }
    }
  }

  .etf-live-stats,.etf-model-stats
  {
    display: flex;
    &.etf-flex-end{
      justify-content: flex-end;
    }

  }

  .etf-summary-bar
  {
    display: flex;
    font-size: 12px;
    color: $color-white;
    height: 65px;
  }
  .etf-summary-bar-new {
    display: flex;
    box-shadow: 0px 2px 2px 0px rgba(0,0,0,0.15);
    padding: 5px;
  }
  .etf-stat-item {
    display: inline-block;
    padding: 0px;
    margin-left: 25px;
  }
  .etf-header-stat-new {
    font-size: 1.1em;
    font-weight: bold;
    margin-top: 2px;
  }



  .etf-sidebar-toggle
  {
    padding: 5px 5px 5px 5px;
    cursor: pointer;
    width: 40px;
    height: 40px;
    text-align: center;
    font-size: 1.3em;
  }
}

.etf-mMkt-nav,.etf-mm-nav {
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.25);
    color: #1c2634;
    font-weight: bold;
    font-size: 1.3em;
    padding: 0;
    .li-right{
      @include bp(above-tablet)
      {
        float: right;
      }

    }
    ul
    {
      margin: 0 auto;
      padding: 0;
      list-style: none;
      margin-left: -16px;

      li
      {
        display: block;
        float: left;
        padding: 10px 12px;


        span
        {
          cursor: pointer;
          line-height: 1.1em;
          font-size: 1em;
          padding: 0 16px;

        }
        &.active,&.active1
        {
          color: #f18f01;
          font-weight: bold;
          border-bottom: solid #f18f01;
        }
      }
    }

}
//.etf-mb-nav,.etf-mm-nav,.etf-oefs-nav,.etf-cefs-nav,.etf-etfs-nav{

.etf-terminal-nav,.etf-oefs-nav,.etf-cefs-nav,.etf-etfs-nav{

  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.25);
  color: #1c2634;
  font-weight: bold;
  font-size: 1.1em;
  padding: 0px;
  .li-right{
    @include bp(above-tablet)
    {
      float: right;
    }

  }
  ul
  {
    display: flex;
    justify-content: flex-start;
    margin: 0 auto;
    padding: 0;
    list-style: none;
    margin-left: -16px;
    li
    {
      display: block;
      float: left;
      padding: 6px 12px;
      span
      {
        cursor: pointer;
        line-height: 2em;
        font-size: 1.1em;
        padding: 0 16px;
      }
      &.active,&.active1
      {
        color: #f18f01;
        font-weight: bold;
        border-bottom: solid #f18f01;
      }
    }
  }
}
.etf-mu-nav, .etf-um-nav {
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.25);
    color: #1c2634;
    font-weight: bold;
    font-size: 1.3em;
    padding: 0;
    .li-right{
      @include bp(above-tablet)
      {
        float: right;
      }

    }
    ul
    {
      margin: 0 auto;
      padding: 0;
      list-style: none;
      margin-left: -16px;

      li
      {
        display: block;
        float: left;
        padding: 10px 12px;


        span
        {
          cursor: pointer;
          line-height: 1.1em;
          font-size: 1em;
          padding: 0 16px;

        }
        &.active,&.active1
        {
          color: #f18f01;
          font-weight: bold;
          border-bottom: solid #f18f01;
        }
      }
    }

}
.etf-mb-nav {
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.25);
    color: #1c2634;
    font-weight: bold;
    font-size: 1.3em;
    padding: 0;
    .li-right{
      @include bp(above-tablet)
      {
        float: right;
      }

    }
    ul
    {
      margin: 0 auto;
      padding: 0;
      list-style: none;
      margin-left: -16px;

      li
      {
        display: block;
        float: left;
        padding: 10px 12px;


        span
        {
          cursor: pointer;
          line-height: 1.1em;
          font-size: 1em;
          padding: 0 16px;

        }
        &.active,&.active1
        {
          color: #f18f01;
          font-weight: bold;
          border-bottom: solid #f18f01;
        }
      }
    }

}

.etf-row.etf-row-head
{
  color: $color-white;
  font-size: 16px;
  background-color: $color-blue;
  .orange
  {
    color: $color-light-orange;
  }
  .live-stat-container{
    //display: none;
    padding-bottom: 0px;
  }
  .etf-live-stat,.etf-model-stat{
    padding-right: 20px;
    font-size: 1.1em;
    font-weight: normal;
    text-shadow: rgb(28, 38, 52) -1px 0px 1px, rgb(28, 38, 52) 0px 1px, rgb(28, 38, 52) 1px 0px, rgb(28, 38, 52) 0px -1px;
    &.off-weight{
      color: red;
      font-weight: bold;
      text-shadow: white 0px 0px 10px;
    }
  }
}
.etf-live-stats,.etf-model-stats
{
  display: flex;
  &.etf-flex-end{
    justify-content: flex-end;
  }
}
.etf-summary-bar,
{
  display: flex;
  font-size: 12px;
  color: $color-white;
  height: 65px;

  margin: 20px 0px 20px 16px;
  border-radius: 10px;
  box-shadow: 0 0 5px #bbb;
  background-color: $color-dark-slate;

  .summary-item
  {
    padding: 10px;
    display: inline-block;
    margin-right: 10px;
  }
  @include bp(up-to-tablet)
  {
    height: 100px;
    overflow-x: scroll;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
  }
}
.content-no-header{
  .etf-section-gated{
    margin-top: 0px;
  }
}
#etf-compare-container
{
  .sub-container{
    display: none;

  }
  .comp-ff-charts{
    display: none;
  }
}
div.ag-theme-alpine div.ag-row {
    font-size: 12px !important;
    white-space: normal !important;
}


.ag-link{
  cursor: pointer;
  text-decoration: underline;
  color: #f18f01;
  font-weight: bold;
  span{
    text-decoration: underline;
    color: #f18f01;
    font-weight: bold;
  }
  &:hover{
    font-weight: bold;
  }

}
.logo-crop {
  height:100%;
 }
 .medal-crop {
   width: 50px;
   height: auto;
  }
  
.etf-sidebar
{
  height: 85%;
  width: 15%;
  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  background-color: $color-light-gray;
  overflow-x: hidden;
  padding: 60px 0px 120px 0px;
  box-shadow: 0 4px 4px 4px rgba(0,0,0,0.25);
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  .etf-sidebar-head{
    :hover{
      color: $color-orange;
    }
    padding: 8px;
    h4{
      font-weight: bold;
    }
    cursor: pointer;
    box-shadow: 0px 1px 1px 1px rgba(0,.5,0,0.25);
    &.active{
      color: $color-orange;
      background-color: $color-dark-slate;
    }


  }
  &::-webkit-scrollbar{
    width: 0;
    height: 0;
  }
  .etf-sidebar-body,.etf-sidebar1-body{
    display: none;
    list-style: none;
    padding-left: 0px;
    border-bottom: 1px solid $color-slate;
      /*overflow-y: scroll;
      scrollbar-width: none;
      -ms-overflow-style: none;
      max-height: 600px;*/

    background-color: $color-white;

    .etf-sidebar-list-head{
      font-size: 14px;
      padding: 5px 5px 5px 10px;

      &.active{
        background-color: $color-light-slate;
        color: $color-white;
        //background-color: $color-turquoise;

      }
      &:hover{
        cursor: pointer;
        text-decoration: underline;
      }
    }
    .etf-sidebar-list{
      display: none;
      list-style: none;
      padding: 0px;
      border-bottom: 1px solid $color-orange;
    }
    .etf-sidebar-list-sub-head{
      font-size: 14px;
      padding: 5px 5px 5px 15px;
      &.active{
        //background-color: $color-light-slate;
        color: $color-white;
        background-color: $color-turquoise;
      }
      &:hover{
        cursor: pointer;

      }
    }
    .etf-sidebar-sub-list{
      display: none;
      list-style: none;
    }
  }
}

#screen-fav-list .fas.fa-star{
  color: gold;
}
.etf-sidebar-body .li-flex,.etf-sidebar1-body .li-flex{
  display: flex;
  padding: 5px;
  margin-bottom: 0px;
}
.etf-sidebar-body .li-flex:hover{

}
.li-gated{

}
.li-gated:hover{
  text-decoration: underline;
  color: $color-turquoise;

  cursor: pointer;
}
.li-f-4{
  flex: 4;
}
.li-f-4 span:hover{
  text-decoration: underline;
  color: $color-turquoise;

  cursor: pointer;
}
.li-f-1{
  flex: 1;
  position: relative;
}
.etf-sidebar-body .li-flex i{
  visibility: hidden;
  cursor: pointer;
  position: absolute;
  top: 10%;
  left: 50%;
  font-size: 16px;
}
.etf-sidebar-body .li-flex:hover i{
  visibility: visible;
}

.etf-play-form.sign-up{
  input,select{
    border: 2px solid $color-orange;
  }
  input:focus,select:focus{
    //border: 2px solid $color-turquoise;
    //box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    box-shadow: 0 4px 8px 0 rgba(0,196,236,0.85);
  }
}
.etf-row-form.form-style{
  padding: 10px;
  input,select,textarea{
    border: 2px solid $color-orange;
    padding: 4px;
    margin: 4px;
    font-size: 1.2em;
    width: 100%;
  }
  button{
    font-size: 1.4em;
    padding: 10px;

    width: 25%;
  }
  label{
    font-weight: bold;
    font-size: 1.2em;
  }
  input:focus,select:focus{
    //border: 2px solid $color-turquoise;
    //box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    box-shadow: 0 4px 8px 0 rgba(0,196,236,0.85);
  }
}
// v3-home-office-merge-user-page
// .etf-row-form-new.form-style-new{
//   padding: 10px;
//   input,select,textarea{
//     padding: 10px;
//     margin-bottom: 15px;
//     margin-top: 5px;
//     border: 1px solid #ccc;
//     border-radius: 8px;
//     font-size: 16px;
//     box-sizing: border-box;
//     width: 100%;
//   }
//   button{
//     font-size: 1.4em;
//     padding: 10px;
//
//     width: 25%;
//   }
//   label{
//     font-weight: bold;
//     margin-bottom: 2px;
//     font-size: 16px;
//     color: #444;
//     text-align: left;
//   }
//   input:focus,select:focus,textarea:focus{
//     //border: 2px solid $color-turquoise;
//     //box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
//     box-shadow: 0 4px 8px 0 $color-orange;
//   }
// }

// v3-home-office-merge-user-page END
// v3-home-office-merge-mm BEGIN
.etf-row-form-new.form-style-new {
  padding: 10px;
}
//
.etf-row-form-new.form-style-new input,
.etf-row-form-new.form-style-new select,
.etf-row-form-new.form-style-new textarea
{
  padding: 6px;
  margin-bottom: 15px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 14px;
  box-sizing: border-box;
  width: 100%;
  height: 30px; /* Ensures consistent height */
  appearance: none; /* Prevents default styles on number inputs */
}
.etf-row-form-new.form-style-new textarea {
  height: 100px;
}

.etf-row-form-new.form-style-new input[type="number"] {
  -moz-appearance: textfield; /* Removes spinner buttons in Firefox */
}

.etf-row-form-new.form-style-new input[type="number"]::-webkit-outer-spin-button,
.etf-row-form-new.form-style-new input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0; /* Removes default margin in Webkit browsers */
}

.etf-row-form-new.form-style-new button {
  font-size: 1.4em;
  padding: 10px;
  width: 25%;
}

.etf-row-form-new.form-style-new label {
  font-weight: bold;
  margin-bottom: 2px;
  font-size: 14px;
  color: #444;
  text-align: left;
}

.etf-row-form-new.form-style-new input:focus,
.etf-row-form-new.form-style-new select:focus,
.etf-row-form-new.form-style-new textarea:focus {
  box-shadow: 0 4px 8px 0 orange;
}
// v3-home-office-merge-mm END


.c-param-toggle,.c-param-selector{
  display: inline-block;
}

#navigator{
  display: none;
}
#navigator-container .dataTables_scrollHead{
  overflow: visible !important;
}
.dataTables_scrollBody thead tr{
  /*visibility: hidden !important;*/
}

.multiselect,.num-filter{
  width: 100%;

  z-index: 10;
  display: inline-block;
  .selectBox{

    select{
      width: 100%;
    }
    .overSelect{
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
    }
  }
  .f-checkboxes{
    display: none;

    position: absolute;
    padding: 5px;
    overflow: scroll;
    max-height: 500px;
    text-align: left;
    border: 1px solid #888;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    background-color: $color-white;
    z-index: 1000;
    label{
      display: block;
    }
    label:hover{
      background-color: $color-turquoise;
    }
  }
  .f-nums{
    display: none;

    position: absolute;
    padding: 5px;
    overflow: scroll;
    max-height: 500px;
    text-align: left;
    border: 1px solid #888;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    background-color: $color-white;
    z-index: 1000;
    .num-filter-box{
      display: block;
    }
  }
}
.screener-label{
  font-size: 1.2em;
  margin-top: 0px;
  display: inline-block;
}
#ticker-box{
  padding-top: 10px;
  display: inline;


  button{
    background-color: $color-gray;
  }
  button.active{
    background-color: $color-orange;
  }
}
#navigator_filter input[type=search]{
  border: 3px solid #f18f01;
}
#navigator_length select{
  border: 3px solid #f18f01;
}
#ticker-box input[type="text"]{
  height: 30px;
  min-width: 600px;
  font-size: 16px;
  margin-bottom: 5px;
}
.selectBox{
  background-color: $color-gray;
  color: $color-white;
  &.active{
    background-color: $color-orange;
  }
}
#reset-table{
  background-color: $color-gray;
  &.active{
    background-color: $color-orange;
  }
  &:hover{
    background-color: $color-orange;
  }
}
.rotate{
    -moz-transition: all .3s linear;
    -webkit-transition: all .3s linear;
    transition: all .3s linear;
}

.rotate.down{
    -ms-transform: rotate(90deg);
    -moz-transform: rotate(90deg);
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}
.model-summary{
  margin: 0px 10px 0px 10px;

  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.25);

  .model-header{
    background-color: $color-dark-blue;
    color: $color-white;
    padding: 15px;
    text-align: center;
  }
  .model-body{
    padding: 15px;

    ul.model-list{
      font-weight: bold;
      color: $color-orange;
      list-style-type: none;
      padding-left: 0px;
      &.model-list-t{
        list-style-type: disc;
      }
      li{
        margin-bottom: 5px;
      }
    }
  }
}
.fas fa-info-circle{
  color: $color-turquoise;
}
#refresh-icon{
    background: url(/assets/images/refresh_icon.png) no-repeat center center;
    color: green;
    background-color: green;
    cursor: pointer;
}
span.toggle-img {
    background: url(/assets/images/details_open.png) no-repeat center center;
    cursor: pointer;
}
span.toggle-img.shown {
    background: url(/assets/images/details_close.png) no-repeat center center;
}
span.toggle-img-new {
    background: url(/assets/images/details_open.png) no-repeat center center;
    cursor: pointer;
}
span.toggle-img-new.shown {
    background: url(/assets/images/details_close.png) no-repeat center center;
}


td.details-control {
    background: url(/assets/images/details_open.png) no-repeat center center;
    cursor: pointer;
}
tr.shown td.details-control {
    background: url(/assets/images/details_close.png) no-repeat center center;
}
.sec-t-x {
    cursor: pointer;
    text-align: center;
}
#sec-own-table tbody td.f-ticker {
    cursor: pointer;
    color: #f18f01;
    font-weight: bold;
    &:hover{
      cursor: pointer;
      text-decoration: underline;

    }
}


.etf-research-item{
  padding: 15px;
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.25);
}
.etf-component-header{
  margin-top: 10px;
  padding: 10px;
  background-color: $color-dark-blue;
  color: $color-white;
  font-size: 1.2em;
  font-weight: bold;
}

.etf-exclude-tab
{

  padding: 5px;
  border: 3px solid $color-orange;
  font-size: 14px;
  display: inline;
  @include bp(above-tablet)
  {
    float: right;
  }
}
#dash-wrapper{
    .dataTables_wrapper
    {
      font-size: 1em;
    }
}
.dash-container{
  display: flex;
  font-size: 11px;
  .dash-col{
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;

    &.span-1{
      flex: 1;
      min-width: 0;
    }
    &.span-2{
      flex: 2;
      min-width: 0;
    }
    &.span-3{
      flex: 3;
      min-width: 0;
    }
    .dash-pane{
      display: flex;
      height: auto;
      width: 100%;
      flex: 1;
      flex-direction: column;

      .t-pane{
        border-radius: 10px;
        margin: 3px;
        box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.25);

        &.active{
          box-shadow: 0px 2px 2px 2px rgba(0,196,236,0.85);
          .t-pane-head{
            color: $color-white;
            background-color: $color-dark-blue;

          }

        }
        .t-pane-head{
          border-radius: 10px 10px 0px 0px;
          padding: 8px;
          min-height: 24px;

          position: relative;
          color: $color-white;
          background-color: $color-gray;

          input,select{
            color: $color-dark-blue;
          }
          .t-pane-name{
            font-size: 1.2em;
          }
          .table-toggle{
            font-size: 18px;
            &:hover{
              cursor: pointer;
              text-decoration: underline;
            }
          }
          .etf-head-nav{
            > li{
              display: inline;
            }
            float: right;
            margin-bottom: 0px;
          }

        }
        .t-summary{
          .summary-bar
          {
            display: flex;
            font-size: 12px;
            height: 65px;

            box-shadow: 0 0 5px #bbb;
            .summary-item
            {
              padding: 10px;
            }
            @include bp(up-to-tablet)
            {
              height: 100px;
              overflow-x: scroll;
              overflow-y: hidden;
              -webkit-overflow-scrolling: touch;
            }

          }

        }

        .t-pane-body{
          padding: 8px;
          min-height: 300px;
        }
        .t-pane-body-chart{
          padding: 0px;
          min-height: 300px;
        }
        .t-pane-foot{
          border-radius: 0px 0px 10px 10px;
          padding: 8px;

          background-color: $color-light-gray;
        }
      }
    }
  }
}
.loading-button-holder{
  display: inline-block;
  position: relative;
}


.highcharts-figure, .highcharts-data-table table {
    min-width: 320px;
    max-width: 600px;
    margin: 1em auto;
}

.highcharts-data-table table {
	font-family: Verdana, sans-serif;
	border-collapse: collapse;
	border: 1px solid #EBEBEB;
	margin: 10px auto;
	text-align: center;
	width: 100%;
	max-width: 500px;
}
.highcharts-data-table caption {
    padding: 1em 0;
    font-size: 1.2em;
    color: #555;
}
.highcharts-data-table th {
	font-weight: 600;
    padding: 0.5em;
}
.highcharts-data-table td, .highcharts-data-table th, .highcharts-data-table caption {
    padding: 0.5em;
}
.highcharts-data-table thead tr, .highcharts-data-table tr:nth-child(even) {
    background: #f8f8f8;
}
.highcharts-data-table tr:hover {
    background: #f1f7ff;
}

.etf-modal-body.etf-file-upload{
  #frame {margin:0 auto; width: auto; text-align:left;}

  /* Uploader: Drag & Drop */
  .resumable-error {display:none; font-size:14px; font-style:italic;}
  .resumable-drop {height: 200px;padding:15px; font-size:13px; text-align:center; color:#666; font-weight:bold;background-color:#eee;
  border:2px dashed #aaa; border-radius:10px; margin-top:10px; z-index:9999; display:none;}
  .dragover {padding:30px; color:#555; background-color:#ddd; border:1px solid #999;}

  /* Uploader: Progress bar */
  .resumable-progress {margin:30px 0 30px 0; width:100%; display:none;}
  .progress-container {height:7px; background:#9CBD94; position:relative; }
  .progress-bar {position:absolute; top:0; left:0; bottom:0; background:#45913A; width:0;}
  .progress-text {font-size:11px; line-height:9px; padding-left:10px;}
  .progress-pause {padding:0 0 0 7px;}
  .progress-resume-link {display:none;}
  .is-paused .progress-resume-link {display:inline;}
  .is-paused .progress-pause-link {display:none;}
  .is-complete .progress-pause {display:none;}

  /* Uploader: List of items being uploaded */
  .resumable-list {overflow:auto; margin-right:-20px; display:none;}
  .uploader-item {width:148px; height:90px; background-color:#666; position:relative; border:2px solid black; float:left; margin:0 6px 6px 0;}
  .uploader-item-thumbnail {width:100%; height:100%; position:absolute; top:0; left:0;}
  .uploader-item img.uploader-item-thumbnail {opacity:0;}
  .uploader-item-creating-thumbnail {padding:0 5px; font-size:9px; color:white;}
  .uploader-item-title {position:absolute; font-size:9px; line-height:11px; padding:3px 50px 3px 5px; bottom:0; left:0; right:0; color:white; background-color:rgba(0,0,0,0.6); min-height:27px;}
  .uploader-item-status {position:absolute; bottom:3px; right:3px;}

  /* Uploader: Hover & Active status */
  .uploader-item:hover, .is-active .uploader-item {border-color:#4a873c; cursor:pointer; }
  .uploader-item:hover .uploader-item-title, .is-active .uploader-item .uploader-item-title {background-color:rgba(74,135,60,0.8);}

  /* Uploader: Error status */
  .is-error .uploader-item:hover, .is-active.is-error .uploader-item {border-color:#900;}
  .is-error .uploader-item:hover .uploader-item-title, .is-active.is-error .uploader-item .uploader-item-title {background-color:rgba(153,0,0,0.6);}
  .is-error .uploader-item-creating-thumbnail {display:none;}
}
.td-action{

  &:hover{
    cursor: pointer;
    text-decoration: underline;
    font-weight: bold;
  }
}

.td-nowrap{
   white-space: nowrap;
}
table.dataTable tr th.select-checkbox.selected::after {
    content: "✔";
    margin-top: -11px;
    margin-left: -4px;
    text-align: center;
    text-shadow: rgb(176, 190, 217) 1px 1px, rgb(176, 190, 217) -1px -1px, rgb(176, 190, 217) 1px -1px, rgb(176, 190, 217) -1px 1px;
}
.td-action{
  text-decoration-style: dashed;
}
.ag-theme-balham {
    .ag-tabs{
      min-width: 350px;
    }

}


#landing-disclosure {
  background-color: $color-light-gray;
  box-shadow: 0 15px 20px 0 rgba(0, 0, 0, .18);
  margin: 30px auto 200px auto;
  padding: 20px;
  width: 95%;

  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: 500px;
  min-width: 300px;
  .disclosure-box{
    background-color: $color-white;
    font-size: 1.2em;
    margin: 20px 100px;
    padding: 50px;
  }

}
.sponsor-callout {
  background-color: $color-light-gray;
  box-shadow: 0 15px 20px 0 rgba(0, 0, 0, .18);
  margin: 20px auto 200px auto;

  padding: 10px;
  width: 95%;

  display: flex;
  justify-content: space-between;
  flex-direction: column;
//   min-height: 500px;
  min-width: 300px;
  .callout-box{
    background-color: $color-white;
    font-size: 1.0em;
    margin: 10px 10px;
    padding: 20px;
    min-height: 350px;
  }
}
#mb-form {
  background-color: $color-light-gray;
  box-shadow: 0 15px 20px 0 rgba(0, 0, 0, .18);
  margin: 30px auto 200px auto;
  padding: 20px;
  width: 95%;

  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: 500px;
  min-width: 300px;
  .select-opt-list{
    list-style: none;

    padding: 10px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    /*
    flex-flow: row nowrap;
    align-items: stretch;
    justify-content: stretch;
    */
    li{
      padding: 20px;
      flex: 1;
      /*flex-grow: 1;*/
      .select-opt{
        display: block;
        padding: 20px;
        min-height: 250px;

        box-shadow: 0 15px 20px 0 rgba(0,0,0,0.18);
        h3{
          text-align: center;
        }


        &.selected{
          /*background-color: #e1e1e1;*/
          background-color: $color-dark-slate;
          color: $color-white;
          box-shadow: 0 15px 20px 0 rgba(0,196,236,0.85);
        }

        &:hover{
          cursor: pointer;
        }
      }

    }
  }

  .multi-form-footer{
    width: 100%;
    /*position: absolute;
    width: 70%;
    margin: auto;
    padding: 20px;
    bottom: 0;
    */
  }

  /* Mark input boxes that gets an error on validation: */
  input.invalid {
    background-color: #ffdddd;
  }

  /* Hide all steps by default: */
  .tab {
    display: none;
    padding: 5px 20px 20px 20px;
  }
  /* Make circles that indicate the steps of the form: */
  .step {
    height: 15px;
    width: 15px;
    margin: 0 2px;
    background-color: #bbbbbb;
    border: none;
    border-radius: 50%;
    display: inline-block;
    opacity: 0.5;
  }

  /* Mark the active step: */
  .step.active {
    opacity: 1;
  }

  /* Mark the steps that are finished and valid: */
  .step.finish {
    background-color: #04AA6D;
  }
}
.chart-cont{
  padding: 10px 0px 10px 0px;
  margin: 10px 0px 10px 0px;
  box-shadow: 0px 1px 1px 2px rgba(0,0,0,0.15);
}
.td-error{
  background-color: #ffbdbd;
}
.td-warning{
  background-color: #FFFF8F;
}
.td-validated{
  background-color: $color-green;

}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  &.switch-sm{
    width: 40px;
    height: 22px;
  }
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}
.slider-sm:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: $color-orange;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

input:checked + .slider-sm:before {
  -webkit-transform: translateX(17px);
  -ms-transform: translateX(17px);
  transform: translateX(17px);
}

/* Rounded sliders */
.slider.slider-sm.round {
  border-radius: 22px;
}

.slider.round:before {
  border-radius: 50%;
}

.perf-switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
}

.perf-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}


.perf-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.perf-slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}


input:checked + .perf-slider {
  background-color: $color-orange;
}

input:focus + .perf-slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .perf-slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}
/* Rounded sliders */

.perf-slider.round {
  border-radius: 17px;
}

.perf-slider.round:before {
  border-radius: 50%;
}
//primary slider
.primary-switch {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 17px;
}

.primary-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}


.primary-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.primary-slider:before {
  position: absolute;
  content: "";
  height: 13px;
  width: 13px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}


input:checked + .primary-slider {
  background-color: $color-orange;
}

input:focus + .primary-slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .primary-slider:before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
}
/* Rounded sliders */

.primary-slider.round {
  border-radius: 17px;
}

.primary-slider.round:before {
  border-radius: 50%;
}

.container{
  flex: 3;
  max-width: 260px;
  min-width: 230px;
  border: 1px solid white;
  box-shadow: 1px 1px 5px 4px lightblue;
  border-radius: 25px;
  margin: 10px;
  text-align: center;
  color: black;
}

.flex-container {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  flex-wrap: wrap;
  justify-content: center;
  width: 95%;
  align: center;
}
@media screen and (max-width: 640px){
  .container{
    display: block;
    width: 100%;
  }
}
.polaroid {
  width:100%;

  height: 160px;
  background-color: white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0),0 6px 20px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 50px;
  border-bottom: 1px solid $color-slate;
}

.content {
  position: relative;
   width: 100%;
   cursor: pointer;
//   width: 200px;
  margin: auto;
  overflow: hidden;
  border-radius: 25px;
}
.content .content-overlay {
  background: rgba(0,0,0,0.7);
  position: absolute;
  height: 75%;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
}
.content:hover .content-overlay{
  opacity: 1;
}
.content-image{
  width: 100%;
}
.content-details {
  position: absolute;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}
.content:hover .content-details{
  top: 50%;
  left: 50%;
  opacity: 1;
}

.content-details h3{
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.15em;
  margin-bottom: .5em;
  text-transform: uppercase;
}
.content-details p{
  color: #fff;
  font-size: 1.2em;
  margin-bottom: 3em;
}
.fadeIn-bottom{
  top: 80%;
}
.fadeIn-top{
  top: 20%;
}
.fadeIn-left{
  left: 20%;
}
.fadeIn-right{
  left: 80%;
}

.text {
      position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
      bottom: 4px;
      left:0;
      right:0;
      height: 50px;
      margin-top: 10px;
      margin-bottom: 0px;
      margin-left:auto;
      margin-right:auto;
  .h-text{
    margin: auto;
  }
}
.frame-holder{
  box-shadow: 0px 1px 1px 1px rgba(0,.5,0,0.25);
}
.target-click{
  color: #f18f01;
  font-weight: bold;
  cursor: pointer;
}
.target-click:hover{
  text-decoration: underline;
}

.simple-table{
    border-collapse: collapse;
    width: 100%;
    tr{
        border-bottom: 1px solid #ddd;
        th,td{
            padding: 10px;

        }
    }
}

.primary-security{
background: '#ffd9a2';
}

.risk-blob-cont{
  display: grid;
  text-align: center;
  .risk-profile-num{
    font-size: 2.5em;
    font-weight: bold;
    grid-column: 1;
    grid-row: 1;
    margin: auto;
    z-index: 100;
  }
}

.riskbase-blob {
	background: black;
	grid-column: 1;
  grid-row: 1;
  margin: auto;
	border-radius: 50%;
	box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);

	//margin: 60px;
	height: 120px;
	width: 120px;
	transform: scale(1);
// 	animation: pulse-black 2s infinite;
}

@keyframes pulse-black {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 60px rgba(0, 0, 0, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}


.riskbase-blob.risk-0 {
	background: rgba(32, 217, 100, 1);
	box-shadow: 0 0 0 0 rgba(32, 217, 100, 1);
// 	animation: pulse-risk-0 2s infinite;
}

@keyframes pulse-risk-0 {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(32, 217, 100, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 60px rgba(32, 217, 100, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(32, 217, 100, 0);
	}
}

.riskbase-blob.risk-1 {
	background: rgba(32, 217, 79, 1);
	box-shadow: 0 0 0 0 rgba(32, 217, 79, 1);
// 	animation: pulse-risk-1 2s infinite;
}

@keyframes pulse-risk-1 {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(32, 217, 79, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 60px rgba(32, 217, 79, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(32, 217, 79, 0);
	}
}
.riskbase-blob.risk-2 {
	background: rgba(32, 217, 32, 1);
	box-shadow: 0 0 0 0 rgba(32, 217, 32, 1);
// 	animation: pulse-risk-2 2s infinite;
}

@keyframes pulse-risk-2 {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(32, 217, 32, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 60px rgba(32, 217, 32, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(32, 217, 32, 0);
	}
}
.riskbase-blob.risk-3 {
	background: rgba(79, 217, 32, 1);
	box-shadow: 0 0 0 0 rgba(79, 217, 32, 1);
// 	animation: pulse-risk-3 2s infinite;
}

@keyframes pulse-risk-3 {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(79, 217, 32, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 60px rgba(79, 217, 32, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(79, 217, 32, 0);
	}
}

.riskbase-blob.risk-4 {
	background: rgba(125, 217, 32, 1);
	box-shadow: 0 0 0 0 rgba(125, 217, 32, 1);
// 	animation: pulse-risk-4 2s infinite;
}
@keyframes pulse-risk-4 {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(125, 217, 32, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 60px rgba(125, 217, 32, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(125, 217, 32, 0);
	}
}
.riskbase-blob.risk-5 {
	background: rgba(171, 217, 32, 1);
	box-shadow: 0 0 0 0 rgba(171, 217, 32, 1);
// 	animation: pulse-risk-5 2s infinite;
}

@keyframes pulse-risk-5 {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(171, 217, 32, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 60px rgba(171, 217, 32, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(171, 217, 32, 0);
	}
}
.riskbase-blob.risk-6 {
	background: rgba(217, 217, 32, 1);
	box-shadow: 0 0 0 0 rgba(217, 217, 32, 1);
// 	animation: pulse-risk-6 2s infinite;
}

@keyframes pulse-risk-6 {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(217, 217, 32, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 60px rgba(217, 217, 32, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(217, 217, 32, 0);
	}
}
.riskbase-blob.risk-7 {
	background: rgba(217, 190, 32, 1);
	box-shadow: 0 0 0 0 rgba(217, 190, 32, 1);
// 	animation: pulse-risk-7 2s infinite;
}

@keyframes pulse-risk-7 {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(217, 190, 32, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 60px rgba(217, 190, 32, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(217, 190, 32, 0);
	}
}
.riskbase-blob.risk-8 {
	background: rgba(218, 165, 32, 1);
	box-shadow: 0 0 0 0 rgba(218, 165, 32, 1);
// 	animation: pulse-risk-8 2s infinite;
}

@keyframes pulse-risk-8 {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(218, 165, 32, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 60px rgba(218, 165, 32, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(218, 165, 32, 0);
	}
}
.riskbase-blob.risk-9 {
	background: rgba(250, 125, 60, 1);
	box-shadow: 0 0 0 0 rgba(250, 125, 60, 1);
// 	animation: pulse-risk-9 2s infinite;
}

@keyframes pulse-risk-9 {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(250, 125, 60, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 60px rgba(250, 125, 60, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(250, 125, 60, 0);
	}
}
.riskbase-blob.risk-10 {
	background: rgba(255, 79, 60, 1);
	box-shadow: 0 0 0 0 rgba(255, 79, 60, 1);
// 	animation: pulse-risk-10 2s infinite;
}
@keyframes pulse-risk-10 {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 79, 60, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 60px rgba(255, 79, 60, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 79, 60, 0);
	}
}


#home-office-terminal{

  .chip-container {
    display: flex;
    flex:11;
align-items: center;

    flex-wrap: wrap;
    background-color: #D3D3D3;
    padding: 8px;
    max-height: 55px;
    overflow-y: auto;
  }

  /* Individual chip styling */
  .chip2 {
    display: inline-flex;

    align-items: center;
    margin: 3px;
    font-size: .9em;

    padding: 0px 8px 1px 8px;
    background-color: #e0e0e0;
    border-radius: 20px;
    transition: background-color 0.3s ease, box-shadow 0.3s ease, color 0.3s ease;
    &.cat1{
      color: #fff;
      background-color: green;
    }
  }
  /* Styling for text inside the chip */
  .chip2-text {
    margin-right: 8px;
  }
  .chip2:hover {
//     background-color: rgba(224, 224, 224, .8);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }
  /* Styling for number inside the chip */
  .chip2-number {
    font-weight: bold;
    margin-right: 3px;
  }

  .chip2-remove{
    padding: 2px;
    margin-top: 1px;
    cursor: pointer;
    font-size: 1.2em;
//     opacity: 0; /* Initially hide the delete icon */
//     transition: opacity 0.3s ease;
  }
//   .chip2:hover .chip2-remove {
//     opacity: 1;
//   }
    .tab-container {
      display: flex;
      justify-content: flex-start;
      border-bottom: thin solid $color-gray;
      margin-bottom: 5px;
    }

    /* Individual tab styling */
    .tab {
      cursor: pointer;
      padding: 10px 20px;
      transition: background-color 0.3s ease;

//       background-color: #3498db;
//       color: #fff;
//       border-radius: 5px;
      &.active{
        border-bottom: solid #f18f01;
      }
    }
    .tab:hover {
      background-color: #e6e6e6;
    }
    /* Styling for the content container */
    .content-container {
      display: flex;
      flex-wrap: wrap;
    }
    /* Styling for the child components */
    .child-component {
      //width: 100%;

      height: 215px; /* Set fixed height */
      padding: 0px;
      font-size: .9em;
      display: none; /* Initially hide child components */
//       flex-direction: column;
      background-color: #f2f2f2;
    }

    /* Styling for the selectable chips */
    .selectable-chip {
      display: block;
      align-items: center;
      width: 100%;
      margin: 0px;
      padding: 6px 6px;
//       background-color: #e0e0e0;
//       border-radius: 20px;
      cursor: pointer;
      transition: background-color 0.3s ease;
      &.active{
        background-color: $color-light-orange;
      }
    }
    .selectable-chip:hover {
      background-color: $color-light-orange;
    }
.ho-filter-toggle{
  cursor: pointer;

  font-size: 1.2em;
  &:hover{
    text-decoration: underline;
  }
}
.etf-header-new {
  padding: 5px;
  margin: 0px;
  border: 1px solid #bdc3c7;
  background-color: #f5f7f7;
}
.etf-auto

.primary-security{
background: '#ffd9a2';
}


input[type="radio"] {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

    /* Show child component on tab selection */
    #tab1:checked ~ #childComponent1,
    #tab2:checked ~ #childComponent2,
    #tab3:checked ~ #childComponent3,
    #tab4:checked ~ #childComponent4,
    #tab5:checked ~ #childComponent5,
    #tab6:checked ~ #childComponent6,
    #tab7:checked ~ #childComponent7,
    #tab8:checked ~ #childComponent8,
    #tab9:checked ~ #childComponent9,
    {
      display: flex;
    }
//     [class*="tab"] ~ [class*="childComponent"]{
//       display: flex;
//     }

        .filter-input {
          display: none;
          margin-bottom: 0px;
          padding: 8px;
          border: 1px solid #ccc;
          border-radius: 0px;
          width: 100%;
          box-sizing: border-box;
          &.active{
            display: block;
          }
        }
        /* Show the filter input and filter chips based on input value */
//         .child-component input[type="text"] {
//           display: block;
//         }


        .child-component input[type="text"] + .selectable-chip {
          display: block;
        }


.child-component .filter-container{
  //flex-grow: 1; /* Allow the filter-container to grow and take available space vertically */
  height: 100%;
  min-width: 160px;
  display: flex;
  flex-direction: column;
}
.child-component .filter-container .filter-list {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  align-items: flex-start;
  //max-height: 200px; /* Ensure the filter-container doesn't exceed the height of .child-component */
  overflow-y: auto; /* Add vertical scrollbar when content exceeds the height */
  overflow-x: hidden;
  border: thin solid $color-light-gray;
}
.filter-head {
  background-color: #f2f2f2; /* Add a background color for the title bar */
   /* Add padding for spacing */
   border: thin solid $color-light-gray;
}

.filter-title {
  display: flex;
  justify-content: space-between; /* Align items to each end of the container */
  align-items: center; /* Center vertically */
  padding: 10px;
  font-size: 12px; /* Adjust font size as needed */
  font-weight: bold; /* Make the title bold */
}
.filter-title-text{
  cursor: pointer;
}
.filter-title-text:hover{
  border-bottom: thin solid #f18f01;
}
.filter-title span:last-child {
  cursor: pointer; /* Add a pointer cursor to indicate interactivity */
  margin-left: 8px;
}
}


.tc-chart-toggle,.si-chart-toggle, .ir-chart-toggle{
  padding: 7px;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  background: rgba(208,207,207,0.8);
  color: #383838;
  border-radius: 22px;
  cursor: pointer;
  &.active{
    background: #f18f01;
    color: #fff;
    border-radius: 22px;
  }
}
.sc-chart-toggle{
  padding: 7px;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  background: rgba(208,207,207,0.8);
  color: #383838;
  border-radius: 22px;
  cursor: pointer;
  &.active{
    background: #f18f01;
    color: #fff;
    border-radius: 22px;
  }
}
.fn-chart-toggle{
  padding: 7px;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  background: rgba(208,207,207,0.8);
  color: #383838;
  border-radius: 22px;
  cursor: pointer;
  &.active{
    background: #f18f01;
    color: #fff;
    border-radius: 22px;
  }
}
.ac-chart-toggle{
  padding: 7px;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  background: rgba(208,207,207,0.8);
  color: #383838;
  border-radius: 22px;
  cursor: pointer;
  &.active{
    background: #f18f01;
    color: #fff;
    border-radius: 22px;
  }
}
.nav-dash-chart-toggle{
  padding: 7px;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
  background: rgba(208,207,207,0.8);
  color: #383838;
  border-radius: 22px;
  cursor: pointer;
  &.active{
    background: #f18f01;
    color: #fff;
    border-radius: 22px;
  }
}

 .data-switch-toggle {
   float: left;
   background: rgba(208, 207, 207, 0.8);
   border-radius: 22px;
}
.data-switch-toggle input {
  position: absolute;
  opacity: 0;
}
.data-switch-toggle input + label {
  padding: 7px;
  float:left;
  color: #383838;
  cursor: pointer;
}
.data-switch-toggle input:checked + label {
  background: #f18f01;
  color: #fff;
  border-radius: 22px;
}
.article-grid {
    display: flex; /* Change grid to flex for horizontal layout */
    gap: 20px; /* Space between cards */
    overflow-x: auto; /* Enable horizontal scrolling */
    scroll-snap-type: x mandatory; /* Optional: for snapping effect */
    padding: 10px; /* Add padding for better aesthetics */
    margin: 0 auto;
    border: 1px solid #ccc; /* Card border */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}


.article-card {
    flex: 0 0 auto; /* Prevent flex items from shrinking or growing */
    width: 200px; /* Define the fixed width of each card */
    max-height: 250px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    transition: transform 0.2s;
}
.article-card .title {
    font-size: 1.2em;
    padding: 15px;
    font-weight: bold;
    text-align: center;
    color: #333;
    &:hover {
      text-decoration: underline;
    }
}


/* Optional: Customize scrollbar for a better user experience */
.article-grid::-webkit-scrollbar {
    height: 10px;
}

.article-grid::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 5px;
}

.article-grid::-webkit-scrollbar-track {
    background-color: #f9f9f9;
}
// .article-grid {
//     display: grid;
//     grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
//     margin: 0 auto;
//     gap: 20px;
//
// }
//
// .article-card {
//     background-color: #fff;
//     border-radius: 8px;
//     box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
//     overflow: hidden;
//     transition: transform 0.2s;
//     .title {
//         font-size: 1.2em;
//         padding: 15px;
//         font-weight: bold;
//         text-align: center;
//         color: #333;
//     }
// }

.article-card:hover {
    transform: translateY(-5px);
}
.thumbnail {
//     width: 100%;
//     height: auto;
    width: 100%;
    height: 180px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.thumbnailTest {
    width: 100%;
    height: 160px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}
.divider {
    height: 1px; /* Height of the divider */
    background-color: #ccc; /* Light gray color for the divider */
    margin: 20px 0; /* Space above and below the divider */
}
.returns-widget {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* 4 columns for labels and data */
    max-width: 600px;
    margin: 0 auto; /* Center the widget */
    min-height: 220px;
    border: 1px solid #ccc; /* Card border */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    margin-bottom: 20px;
    overflow: hidden;
}
.grid-header {
    background-color: #f0f0f0; /* Light gray background for header */
}
.header-cell {
    padding: 15px;
    font-size: 1.1em;
    text-align: center;
    font-weight: bold;
    //border: 1px solid #ccc;

}


.grid-row {
    display: contents; /* Allows for proper layout of the grid */
}

.return-cell {
    padding: 15px;
    font-size: 1.1em;
    text-align: center;
    border: 1px solid #eee;
}

.positive {
    background-color: #d4edda; /* Light green for positive returns */
    color: #155724; /* Dark green text */
}

.negative {
    background-color: #f8d7da; /* Light red for negative returns */
    color: #721c24; /* Dark red text */
}


.widget-container {
    //max-width: 360px; /* Maximum width of the widget */
    margin: 0; /* Center the widget */
}
.widget-title {
    display: flex; /* Use flexbox to align items */
    justify-content: space-between; /* Space between title text and link */
    align-items: center; /* Center vertically */
    font-size: 1.2em; /* Title font size */
    font-weight: bold; /* Bold title */
    margin-bottom: 10px; /* Space between title and widget */
    //border-bottom: 1px solid #ccc; /* Light grey bottom border */
    padding-bottom: 5px; /* Space between title text and border */
}

.title-text {
    flex-grow: 1; /* Allow the title text to grow */
    text-align: left;
}

.title-link {
    text-decoration: none; /* Remove underline from link */
    color: #007bff; /* Blue color for the link */
    font-weight: bold; /* Normal weight for the link */
}

.image-container {
    display: flex;
    flex-wrap: wrap; /* Allows items to wrap to a new row */
    gap: 10px; /* Adds spacing between items */
    justify-content: center;; /* Aligns items to the start of the row */
    padding: 0;
    margin-top: 10px;
}

.sponsor-img {
    width: 300px;
    box-sizing: border-box; /* Ensures padding/borders are included in width */
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
    text-align: center;
    padding: 10px;
    aspect-ratio: 772 / 485;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
     
}

.sponsor-img img {
    width: 100%;
    object-fit: contain;
    border: 2px solid #bdc3c7;
}

.sponsor-img:hover {
    transform: scale(1.05); /* Slight zoom on hover for container */
}

.sponsor-title {
    text-align: center;
    margin-top: 5px;
    transition: color 0.2s ease-in-out;
}

.sponsor-img:hover .title {
    color: #2980b9; /* Change title color when parent container is hovered */
}


.link-list {
  list-style: none; /* Remove default bullets */
  padding: 0;
  margin: 0;
  overflow-y: auto; /* Enables vertical scrolling */
  overflow-x: hidden;
}

.link-list li {
  line-height: 1.5; /* Set line height */
  margin-bottom: 8px; /* Add space between items */
  display: flex; /* Align icon and text inline */
  align-items: center;
  color: #86adbd;
}

.link-list li::before {
  content: "➤"; /* Unicode arrow */
  margin-right: 8px; /* Space between arrow and link */
  color: #555; /* Arrow color */
  font-size: 18px; /* Arrow size */
}

.link-list a {
  text-decoration: none; /* Remove underline */
  color: #86adbd; /* Link color */
  font-size: 18px;
}

.link-list a:hover {
  text-decoration: underline; /* Underline on hover */
}
.sponsor-link{
  color: #86adbd;
}
.sponsor-content-container {
  height: 230px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 50px;
  margin-top: 10px;
  box-sizing: border-box;
}
.sponsor-content-header {
  font-size: 18px;
  color: #515e70;
  padding: 10px;
  font-weight: bold;
  margin-top: 15px;
}


.icon-box {
  width: 100%;
  max-width: 300px;
  aspect-ratio: 300 / 169;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 2px solid #bdc3c7;
  background-color: #f9f9f9;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  box-sizing: border-box;
}

.icon-box img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
  display: block;
}

.icon-image {
  width: 400px;
  aspect-ratio: 300 / 169;
  border: 2px solid #bdc3c7;
  background-color: #f9f9f9;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.2);
  object-fit: contain;
  display: block;
  margin-bottom: 10px;
  box-sizing: border-box;
}



.bullet-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 8px;
  margin-left: 15px;
}

.bullet {
    width: 1.5em;
    flex-shrink: 0;

}


.bullet-text {
    flex-grow: 1;
}
#sponsor-prof br {
  display: block;
  margin: 2px 0 !important;

}

.thank-you-banner {
  background-image: url('/assets/images/sponsor_banner_test2.jpg');
  background-size: cover; /* Ensures the image covers the entire container */
  background-position: center; /* Centers the image */
   /* Matches the original image height */
  display: flex; /* Flexbox for centering text */
  justify-content: left; /* Centers horizontally */
  align-items: center; /* Centers vertically */
  position: relative; /* Ensures child elements like the text are positioned correctly */
}

.banner-overlay-text {

  color: white; /* Text color for visibility */
  font-size: 30px; /* Adjust font size as needed */
  font-weight: bold; /* Optional for emphasis */
  text-align: left; /* Centers the text */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); /* Adds shadow for better readability */
}


.bar-chart-widget {
    //max-width: 360px;
    margin: 0; /* Center the widget */

    border: 1px solid #ccc; /* Card border */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

.bar {
    display: flex;
    align-items: center;
    margin: 15px 5px;

    .label {
        width: 100px; /* Fixed width for labels */
        font-size: 1.1em;
        text-align: left;
        padding-right: 10px;
//         padding-left: 5px;
        font-weight: bold;
    }
}

.bar-container {
    flex: 1;
//     margin-right: 5px;
    height: 30px; /* Height of the bar */
    background-color: #e9ecef; /* Light gray background for the bar container */
    position: relative;
}

.bar-fill {
    position: absolute;
    height: 100%;
    top: 0;
    transition: width 0.3s ease; /* Smooth transition for width changes */
    max-width: 100%; /* Ensure that the bar does not exceed the container */
    box-sizing: border-box; /* Include padding and border in the element's total width */
}

.positive-bar {
    background-color: #28a745; /* Green for positive returns */
    left: 50%; /* Start from the midpoint */
    transform: translateX(0%); /* Start from the midpoint */
}

.negative-bar {
    background-color: #dc3545; /* Red for negative returns */
    right: 50%; /* Start from the midpoint */
    transform: translateX(0%); /* Start from the midpoint */
}
/* Label text styles */
.label-text {
    position: absolute;
    font-size: 1.1em;
    top: 50%; /* Center vertically */
    transform: translateY(-50%); /* Adjust to vertically center the label */
    color: white; /* Text color for better visibility */
    font-weight: bold;
}

/* Adjust position for positive and negative bars */
.positive-bar .label-text {
    right: 100%; /* Position the label to the left of the midpoint */
    margin-right: 5px; /* Space between label and bar */
    color: #155724; /* Dark green text */
}

.negative-bar .label-text {
    left: 100%; /* Position the label to the right of the midpoint */
    margin-left: 5px; /* Space between label and bar */
    color: #721c24; /* Dark red text */
}

// .sparkline-card {
//     border: 1px solid #ccc; /* Card border */
//     border-radius: 8px; /* Rounded corners */
//     box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
//     width: 200px; /* Card width */
//     margin: 15px; /* Center the card */
//     overflow: hidden; /* Prevent overflow */
// }


.sparkline-container {
    display: flex; /* Flexbox for horizontal layout */
    overflow-x: auto; /* Enable horizontal scrolling */
    white-space: nowrap; /* Prevent wrapping */
}
.sparkline-container::-webkit-scrollbar {
    height: 10px;
}

.sparkline-container::-webkit-scrollbar-thumb {
    background-color: #ccc;
    border-radius: 5px;
}

.sparkline-container::-webkit-scrollbar-track {
    background-color: #f9f9f9;
}


.sparkline-card {
    border: 1px solid #ccc; /* Card border */
    border-radius: 8px; /* Rounded corners */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
    width: 200px; /* Card width */
    margin: 10px; /* Space between cards */
    flex: 0 0 auto; /* Prevent flex items from shrinking */
}
.card-title {
//     background-color: #f5f5f5; /* Title background */
    padding: 8px; /* Padding for title */
    text-align: left; /* Center title text */
    font-weight: bold; /* Bold title */
}
.card-body {
    display: flex; /* Flexbox for layout */
    padding: 3px; /* Padding for body */
    .sparkline-inner {
        flex: 2; /* Take half the space */
        position: relative; /* Positioning for sparkline */
        .sparkline-chart {
          width: 120px;
          height: 40px;
        }
    }

    .return-value {
        flex: 1; /* Take half the space */
        display: flex; /* Center the text */
        align-items: center; /* Vertical center */
        justify-content: center; /* Horizontal center */
        font-size: 1.2em; /* Font size for return */
    }
}
#map-container {
  height: 220px;
  min-height: 220px;
  border: 1px solid #ccc; /* Card border */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  margin: 0 auto;
}

.form-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.form-label {
    margin-bottom: 2px;
    font-size: 16px;
    color: #444;
    text-align: left;
}

.form-input {
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 16px;
    width: 50%;
    box-sizing: border-box;
}

// .custom-dropdown {
//   position: relative;
//   display: inline-block;
//   width: 200px;
//   font-family: Arial, sans-serif;
// }
//
// .dropdown-selected {
//   padding: 10px;
//   border: 1px solid #ccc;
//   background-color: #f9f9f9;
//   cursor: pointer;
//   border-radius: 4px;
// }
//
// .dropdown-options {
//   position: absolute;
//   top: 100%;
//   left: 0;
//   width: 100%;
//   border: 1px solid #ccc;
//   background-color: #fff;
//   display: none;
//   z-index: 10;
//   border-radius: 4px;
//   box-shadow: 0 2px 5px rgba(0,0,0,0.1);
// }
//
// .dropdown-option {
//   padding: 10px;
//   cursor: pointer;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
// }
//
// .dropdown-option:hover {
//   background-color: #f0f0f0;
// }
//
// .delete-icon {
//   cursor: pointer;
//   color: #999;
//   margin-left: 10px;
//   font-size: 16px;
// }
//
// .delete-icon:hover {
//   color: #e74c3c;
// }

//// HERE IS CURRENT
// .custom-dropdown {
//   position: relative;
//   display: inline-block;
//   width: 200px;
//   font-family: Arial, sans-serif;
//   outline: none; /* Prevents focus outline */
// }
//
// .dropdown-selected {
//   padding: 10px;
//   width: 100%;
//   border: 1px solid #ccc;
//   background-color: #f9f9f9;
//   cursor: pointer;
//   border-radius: 4px;
// }
//
// .dropdown-options {
//   position: absolute;
//   top: 100%;
//   left: 0;
//   width: 100%;
//   border: 1px solid #ccc;
//   background-color: #fff;
//   display: none;
//   z-index: 10;
//   border-radius: 4px;
//   box-shadow: 0 2px 5px rgba(0,0,0,0.1);
// }
//
// .dropdown-option {
//   padding: 10px;
//   cursor: pointer;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
// }
// .dropdown-option:hover {
//   background-color: #f0f0f0;
// }
//
// .delete-icon {
//   cursor: pointer;
//   color: #999;
//   margin-left: 10px;
//   font-size: 16px;
// }
// .delete-icon:hover {
//   color: #e74c3c;
// }

.custom-dropdown {
  position: relative;
  display: inline-block;
  min-width: 280px;
  font-family: Arial, sans-serif;
  outline: none; /* Prevents focus outline */
}

.dropdown-selected {
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
  border-radius: 4px;
}

.dropdown-options {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;

  border: 1px solid #ccc;
  background-color: #fff;
  display: none;
  z-index: 10;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.dropdown-option {
  padding: 10px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-option:hover {
  background-color: #f0f0f0;
}

.delete-icon {
  cursor: pointer;
  color: #999;
  margin-left: 10px;
  font-size: 16px;
}

.delete-icon:hover {
  color: #e74c3c;
}

.table-header-flex{
  display: flex;
  padding: 5px;
  margin: 0px;
  border: 1px solid #bdc3c7;
  background-color: #f5f7f7;
  .table-head-flex-item{
    display: flex;
    align-items: center;
    margin: 0px 5px;
  }
}

.ticker-container {
  width: 100%; /* Full width */
  overflow: hidden; /* Hide anything outside the container */
  position: relative;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}


.ticker {
  display: flex; /* Align images horizontally */
  animation: ticker-animation 5s linear infinite; /* Adjust speed here */
  will-change: transform;
//   align-items: center;
//   justify-content: center;
}

.ticker img {
  //height: 100px; /* Adjust image height */
  width: 160px;
  margin-right: 30px; /* No margin between images */
  margin-left: 30px;

}


/* Keyframes for scrolling */
@keyframes ticker-animation {
  0% {
    transform: translateX(0); /* Start at the beginning */
  }
  100% {
    transform: translateX(-220px); /* Scroll images left by the total width */
    //transform: translateX(-100%);
  }
}

.sponsor-scroll-img{
  cursor: pointer;
}