

.etf-perf-table
{
    font-size: 14px;


    .ma{
          width: 150px;
          height: 40px;
    }
    #label-50d{
      margin-right: 25px;
      padding: 2px;

      background-color: #e59599;
    }
    #label-200d{
      padding: 2px;
      background-color: #95c3d8;
    }

}
.etf-row{
    @include bp(above-pc-portrait){
        .etf-text-container{
          margin-right: 20px;
        }
        /*.etf-col-right{
          .etf-text-container{
            padding-right: 0px;
            padding-left: 20px;
          }
        }*/
    }
}
.etf-text-container{
    max-height: 250px;
    overflow: auto;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.125);
    .etf-text-table
    {
        font-size: 14px;

        background-color: $color-slate;
        width: 100%;
        border: 1px solid $color-slate;


        td{
          padding: 10px 5px 5px 5px;
          border-bottom: 1px solid $color-dark-blue;
          text-align: center;
        }
        th{
          border-bottom: 1px solid $color-light-slate;
        }
    }
}

.style-table{
  margin: 10px 30px 0px 0px;
  width: 100%;
  border-collapse: collapse;
  border: 1px solid black;
  font-size: 12px;
  text-align: center;

  th{
    font-weight: normal;
    border: 1px solid black;
    p{
      border-bottom: 1px solid #141140;
    }
  }
  td{
    border: 1px solid black;
    padding: 8px;
    text-align: center;
  }
  .vert-label{
    transform: rotate(-90.0deg);
    p{
      border-bottom: 1px solid #141140;
    }
  }
}

.rets-simple{
  font-size: 12px;
  /*width: 320px;*/
  width: 100%;
  margin: 30px 30px 0px 0px;

  .sub-head-row th{
    font-size: 14px;
    font-weight: normal;
    padding-top: 5px;
    border-bottom: 1px solid black;
  }
  .header-row{
     .row-id{
       text-align: left;
    }

    th{
      font-size: 16px;
      font-weight: normal;
      text-align: right;

      color: #141140;
      border-bottom: 2px solid #141140;
    }
  }
  td{
    text-align: right;
    padding: 2px 0px 2px 0px;
  }
  .row-id{
    max-width: 130px;
    text-align: left;
  }
}




.price-line{
  width: 360px;
  height: 250px;
}

#spy-chart{
  width: 350px;
  height: 300px;
}

.commentary-page{
    .pos-return{
      font-weight: bold;
      color: green;
    }
    .neg-return{
      font-weight: bold;
      color: red;
    }
    p{
      padding: 14px 0px 14px 0px;
    }

}