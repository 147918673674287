.etf-team-link
{
	display: inline-block;
	text-align: center;
	
	img
	{
		border: 2px solid $color-turquoise;
		display: block;
		margin-bottom: 0.5em;
	}
}
