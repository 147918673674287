@font-face
{
	font-display: swap;
	font-family: 'Arvo';
	font-style: italic;
	font-weight: 400;
	src: local('Arvo Italic'), local('Arvo-Italic'), url(../webfonts/arvo-italic.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face
{
	font-display: swap;
	font-family: 'Arvo';
	font-style: italic;
	font-weight: bold;
	src: local('Arvo Bold Italic'), local('Arvo-BoldItalic'), url(../webfonts/arvo-bold-italic.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face
{
	font-display: swap;
	font-family: 'Arvo';
	font-style: normal;
	font-weight: 400;
	src: local('Arvo'), url(../webfonts/arvo.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face
{
	font-display: swap;
	font-family: 'Arvo';
	font-style: normal;
	font-weight: bold;
	src: local('Arvo Bold'), local('Arvo-Bold'), url(../webfonts/arvo-bold.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face
{
	font-display: swap;
	font-family: 'Muli';
	font-style: italic;
	font-weight: 400;
	src: local('Muli Italic'), local('Muli-Italic'), url(../webfonts/muli-italic.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face
{
	font-display: swap;
	font-family: 'Muli';
	font-style: italic;
	font-weight: 600;
	src: local('Muli SemiBold Italic'), local('Muli-SemiBoldItalic'), url(../webfonts/muli-semibold-italic.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face
{
	font-display: swap;
	font-family: 'Muli';
	font-style: italic;
	font-weight: bold;
	src: local('Muli Bold Italic'), local('Muli-BoldItalic'), url(../webfonts/muli-bold-italic.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face
{
	font-display: swap;
	font-family: 'Muli';
	font-style: italic;
	font-weight: 800;
	src: local('Muli ExtraBold Italic'), local('Muli-ExtraBoldItalic'), url(../webfonts/muli-extrabold-italic.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face
{
	font-display: swap;
	font-family: 'Muli';
	font-style: normal;
	font-weight: 400;
	src: local('Muli Regular'), local('Muli-Regular'), url(../webfonts/muli.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face
{
	font-family: 'Muli';
	font-style: normal;
	font-weight: 600;
	font-display: swap;
	src: local('Muli SemiBold'), local('Muli-SemiBold'), url(../webfonts/muli-semibold.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face
{
	font-display: swap;
	font-family: 'Muli';
	font-style: normal;
	font-weight: bold;
	src: local('Muli Bold'), local('Muli-Bold'), url(../webfonts/muli-bold.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

@font-face
{
	font-display: swap;
	font-family: 'Muli';
	font-style: normal;
	font-weight: 800;
	src: local('Muli ExtraBold'), local('Muli-ExtraBold'), url(../webfonts/muli-extrabold.woff2) format('woff2');
	unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

$font-body: 'Muli', sans-serif;
//$font-heading: 'Arvo', serif;
$font-heading: 'Muli', sans-serif;

$font-base-margin: 1;
$font-base-size: 16;
$font-small-size: 14;

$font-line-height-min: 1;
$font-line-height-max: 1.25;
