@include keyframes(etf-spinning)
{
	0%
	{
		@include rotate(0deg);
	}
	
	100%
	{
		@include rotate(360deg);
	}
}
