@mixin animation($name, $duration: 2s, $easing: ease-in-out, $delay: 0s, $iteration-count: infinite, $direction: normal, $fill-mode: none, $play-state: running)
{
	@if ($play-state == running)
	{
		-moz-animation: $name $duration $easing $delay $iteration-count $direction $fill-mode;
		-ms-animation: $name $duration $easing $delay $iteration-count $direction $fill-mode;
		-o-animation: $name $duration $easing $delay $iteration-count $direction $fill-mode;
		-webkit-animation: $name $duration $easing $delay $iteration-count $direction $fill-mode;
		animation: $name $duration $easing $delay $iteration-count $direction $fill-mode;
	}
	@else
	{
		-moz-animation: $name $duration $easing $delay $iteration-count $direction $fill-mode $play-state;
		-ms-animation: $name $duration $easing $delay $iteration-count $direction $fill-mode $play-state;
		-o-animation: $name $duration $easing $delay $iteration-count $direction $fill-mode $play-state;
		-webkit-animation: $name $duration $easing $delay $iteration-count $direction $fill-mode $play-state;
		animation: $name $duration $easing $delay $iteration-count $direction $fill-mode $play-state;
	}
}

@mixin backface-visibility($visibility: hidden)
{
	-moz-backface-visibility: $visibility;
	-ms-backface-visibility: $visibility;
	-o-backface-visibility: $visibility;
	-webkit-backface-visibility: $visibility;
	backface-visibility: $visibility;
}

@mixin border-box()
{
	@include box-sizing(border-box);
}

@mixin border-radius($top-left: 50%, $top-right: false, $bottom-right: false, $bottom-left: false)
{
	@if ($top-right == false)
	{
		-webkit-border-radius: $top-left;
		border-radius: $top-left;
	}
	@elseif ($bottom-right == false)
	{
		-webkit-border-radius: $top-left $top-right;
		border-radius: $top-left $top-right;
	}
	@elseif ($bottom-left == false)
	{
		-webkit-border-radius: $top-left $top-right $bottom-right;
		border-radius: $top-left $top-right $bottom-right;
	}
	@else
	{
		-webkit-border-radius: $top-left $top-right $bottom-right $bottom-left;
		border-radius: $top-left $top-right $bottom-right $bottom-left;
	}
}

@mixin box-shadow($x, $y: 0, $blur: 0, $spread: 0, $color: rgba(black, .25), $inset: false)
{
	@if ($x == none)
	{
		-webkit-box-shadow: $x;
		box-shadow: $x;
	}
	@elseif ($inset)
	{
		-webkit-box-shadow: inset $x $y $blur $spread $color;
		box-shadow: inset $x $y $blur $spread $color;
	}
	@else
	{
		-webkit-box-shadow: $x $y $blur $spread $color;
		box-shadow: $x $y $blur $spread $color;
	}
}

@mixin box-sizing($box-sizing)
{
	-moz-box-sizing: $box-sizing;
	-webkit-box-sizing: $box-sizing;
	box-sizing: $box-sizing;
}

@mixin columns($count: 3, $gap: 48px)
{
	-moz-column-count: $count;
	-webkit-column-count: $count;
	column-count: $count;
	-moz-column-gap: $gap;
	-webkit-column-gap: $gap;
	column-gap: $gap;
	
	> *
	{
		page-break-inside: avoid;
		-webkit-column-break-inside: avoid;
		break-inside: avoid;
	}
}

@mixin content-box()
{
	@include box-sizing(content-box);
}

@mixin flip-horizontally()
{
	@include transform(scaleX(-1));
	@include transform-style();
	
	filter: FlipH;
	-ms-filter: 'FlipH';
}

@mixin flip-vertically()
{
	@include transform(scaleY(-1));
	@include transform-style();
	
	filter: FlipV;
	-ms-filter: 'FlipV';
}

@mixin font-face($family, $file_name, $weight: normal, $style: normal, $types: [eot, svg, ttf, woff, woff2])
{
	$has-eot: false;
	$src-list: ();
	$path: '\.\./webfonts/#{$file_name}';
	$anchors: (eot: '?#iefix', otf: '', svg: '##{$file_name}', ttf: '', woff: '', woff2: '');
	$formats: (eot: 'embedded-opentype', otf: 'opentype', svg: 'svg', ttf: 'truetype', woff: 'woff', woff2: 'woff2');
	
	@each $type in to-array($types)
	{
		$type: to_lower_case($type);
		
		@if ($type == 'eot')
		{
			$has-eot: true;
		}
		
		$src_list: append($src-list, url(#{$path}.#{$type}#{map-get($anchors, $type)}) format('#{map-get($formats, $type)}'), comma);
	}
	
	@if ($has-eot == true)
	{
		@font-face
		{
			font-family: $family;
			src: url(#{$path}.eot);
			src: $src_list;
			font-weight: $weight;
			font-style: $style;
		}
	}
	@else
	{
		@font-face
		{
			font-family: $family;
			src: $src_list;
			font-weight: $weight;
			font-style: $style;
		}
	}
}

@mixin horizontal-gradient($color-1, $color-2, $opacity-1: 1, $opacity-2: 1, $start: 0%, $end: 100%)
{
	@include linear-gradient(90, $color-1, $color-2, $opacity-1, $opacity-2, $start, $end);
}

@mixin keyframes($name)
{
	@-moz-keyframes #{$name}
	{
		@content;
	}
	
	@-ms-keyframes #{$name}
	{
		@content;
	}
	
	@-o-keyframes #{$name}
	{
		@content;
	}
	
	@-webkit-keyframes #{$name}
	{
		@content;
	}
	
	@keyframes #{$name}
	{
		@content;
	}
}

@mixin linear-gradient($angle, $color-1, $color-2, $opacity-1: 1, $opacity-2: 1, $start: 0%, $end: 100%)
{
	$rgba-1: rgba($color-1, $opacity-1);
	$rgba-2: rgba($color-2, $opacity-2);
	$gradient-type: 1;
	
	@if ($angle == 180)
	{
		$gradient-type: 0;
	}
	
	background: $color-1;
	background: $rgba-1;
	background: -moz-linear-gradient(#{$angle - 180}deg, $rgba-1 $start, $rgba-2 $end);
	background: -webkit-linear-gradient(#{$angle - 180}deg, $rgba-1 $start, $rgba-2 $end);
	background: linear-gradient(#{$angle}deg, $rgba-1 $start, $rgba-2 $end);
	filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#{$color-1}', endColorstr='#{$color-2}', GradientType=#{$gradient-type});
}

@mixin link-color($color: inherit, $hover_color: '')
{
	a:not(.#{$theme-prefix}-button)
	{
		color: $color;
		
		&:link,
		&:visited,
		&:hover
		&:focus,
		&:active
		{
			color: $color;
		}
	}
	
	@if ($hover_color != '')
	{
		a:not(.#{$theme-prefix}-button)
		{
			&:hover,
			&.#{$theme-prefix}-active
			{
				color: $hover_color;
			}
		}
	}
}

@mixin menu-reset()
{
	margin: 0 auto;
	padding: 0;
	list-style: none;

	li
	{
		padding: 0;

		a
		{
			display: block;
		}

		ul
		{
			margin: 0;
			padding: 0;
			list-style: none;
		}
	}
}

@mixin placeholder-color($color)
{
	&::-webkit-input-placeholder
	{
		color: $color;
	}
	
	&:-moz-placeholder
	{
		color: $color;
	}
	
	&::-moz-placeholder
	{
		color: $color;
	}

	&:-ms-input-placeholder
	{
		color: $color;
	}
}

@mixin rotate($degrees)
{
	@include transform(rotate($degrees));
	@include transform-style();
}

@mixin rotate-x($degrees)
{
	@include transform(rotateX($degrees));
	@include transform-style();
}

@mixin rotate-y($degrees)
{
	@include transform(rotateY($degrees));
	@include transform-style();
}

@mixin text-shadow($x: 2px, $y: 2px, $blur: 4px, $color: black)
{
	@if ($x == none)
	{
		text-shadow: $x;
	}
	@else
	{
		text-shadow: $x $y $blur $color;
	}
}

@mixin transform($value)
{
	-moz-transform: $value;
	-ms-transform: $value;
	-o-transform: $value;
	-webkit-transform: $value;
	transform: $value;
}

@mixin transform-origin($value: 50% 50%)
{
	-moz-transform-origin: $value;
	-ms-transform-origin: $value;
	-o-transform-origin: $value;
	-webkit-transform-origin: $value;
	transform-origin: $value;
}

@mixin transform-style($style: preserve-3d)
{
	-moz-transform-style: $style;
	-ms-transform-style: $style;
	-o-transform-style: $style;
	-webkit-transform-style: $style;
	transform-style: $style;
}

@mixin transition($properties, $speed: 125ms, $easing: ease-in-out)
{
	$transition-list: ();
	
	@if ($properties == none)
	{
		$transition-list: none !important;
	}
	@else
	{
		@each $transition in to-array($properties)
		{
			@if (type-of($transition) == list)
			{
				$nested: to-array($transition);
				
				@if (length($nested) == 1)
				{
					$nested: append($nested, $speed, space);
				}
				
				@if (length($nested) == 2)
				{
					$nested: append($nested, $easing, space);
				}
				
				$transition-list: append($transition-list, $nested);
			}
			@else
			{
				$transition-list: append($transition-list, $transition $speed $easing, comma);
			}
		}
	}
	
	-moz-transition: $transition-list;
	-ms-transition: $transition-list;
	-o-transition: $transition-list;
	-webkit-transition: $transition-list;
	transition: $transition-list;
}

@mixin translate-x($value: 50%)
{
	@include transform(translateX($value));
}

@mixin translate-xy($x-value: 50%, $y-value: 50%)
{
	@include transform(translateX($x-value) translateY($y-value));
}

@mixin translate-y($value: 50%)
{
	@include transform(translateY($value));
}

@mixin vertical-gradient($color-1, $color-2, $opacity-1: 1, $opacity-2: 1, $start: 0%, $end: 100%)
{
	@include linear-gradient(180, $color-1, $color-2, $opacity-1, $opacity-2, $start, $end);
}
