@font-face
{
	font-display: swap;
	font-family: 'ETF Action';
	font-style: normal;
	font-weight: normal;
	src: url('../webfonts/etf-action.eot');
	src: url('../webfonts/etf-action.woff2') format('woff2'), url('../webfonts/etf-action.eot#iefix') format('embedded-opentype'), url('f../webonts/etf-action.ttf') format('truetype'),  url('../webfonts/etf-action.woff') format('woff'), url('../webfonts/etf-action.svg#etf-action') format('svg');
}

[class^="etf-icon-"],
[class*=" etf-icon-"]
{
	display: inline-block;
	font-family: 'ETF Action' !important;
	font-size: 1.25em;
	font-style: normal;
	font-variant: normal;
	font-weight: normal;
	line-height: 1;
	padding-bottom: 0.125em;
	speak: none;
	text-transform: none;
	vertical-align: middle;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
}

.etf-icon-
{
	&daily-note::before
	{
		content: '\e900';
	}
	
	&engage-and-adapt::before
	{
		content: '\e901';
	}
	
	&etf-screens::before
	{
		content: '\e902';
	}
	
	&etf-terminal::before
	{
		content: '\e903';
		/*content: url(/assets/images/etf-action.png);*/
	}
	
	&have-a-plan::before
	{
		content: '\e904';
	}
	
	&linkedin::before
	{
		content: '\e905';
	}
	
	&playbook::before
	{
		content: '\e906';
	}
	
	&tell-a-story::before
	{
		content: '\e907';
	}
	
	&twitter::before
	{
		content: '\e908';
	}
	
	&beta-trackers::before
	{
		content: '\e909';
	}
	
	&etf-models::before
	{
		content: '\e90a';
	}
	
	&facebook::before
	{
		content: '\e90b';
	}
	
	&link::before
	{
		content: '\e90c';
	}
}
