/*html,body {
    font-family: 'Muli', sans-serif;
    font-size: 12px;
    line-height: 1;
}*/
/*@font-face {
  font-family: 'Muli';
  font-style: normal;
  font-weight: 400;
  src: local('Muli Regular'), local('Muli-Regular'), url(https://dev.etfaction.com/assets/webfonts/muli-v13-latin-regular.woff) format('woff');
}
@font-face
{
	font-family: 'Muli';
	font-style: italic;
	font-weight: 400;
	src: local('Muli Italic'), local('Muli-Italic'), url(https://dev.etfaction.com/assets/webfonts/muli-v13-latin-italic.woff) format('woff');
}

@font-face {
  font-family: 'Arvo';
  font-style: normal;
  font-weight: 400;
  src: local('Arvo'), url(https://dev.etfaction.com/assets/webfonts/arvo-v12-latin-regular.woff) format('woff');
}*/
.page{
    background-color: white;
    position: relative;
    padding: 0px 30px 0px 30px;
    width: 756px;
    height: 1056px;

    /*padding-bottom: 60px;*/
    page-break-after: always;
    border: 1px solid black;

    .header{
      padding: 10px 0;
      border-bottom: 2px solid $color-slate;
      width: 756px;

      img{
       height: 40px;
      }

      .header-title{
        font-size: 18px;
        text-align: center;
      }

    }
    .p-h4{
      font-size: 14px;
      margin: 8px 0;

    }
    .t-wrapper-sm{
      font-size: 9px;
      margin: 10px 0;

      /*border-radius: 10px;*/
      box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.25);
      .t-head{
        /*border-radius: 10px 10px 0px 0px;*/
        padding: 4px;
        position: relative;
        color: $color-white;
        background-color: $color-dark-blue;

      }

      .t-body{
        padding: 8px;
      }
      .t-foot{
        /*border-radius: 0px 0px 10px 10px;*/
        padding: 8px;
        background-color: $color-light-gray;


      }
    }

    .t-col-2 .chart,.t-col-1 .chart{
      font-size: 8px;
      height: 250px;
    }
    .t-col-2 td{
      width: 50%;
    }
    .t-col-3 td{
      width: 33%;
    }
    .t-col-4 td{
      width: 25%;
    }

    table{
      width: 100%;
    }
    td.center{
      text-align: center;
    }
    td.right{
      text-align: right;
    }
    &.page-l{
      width: 996px;
      height: 816px;
      .footer{
        width: 996px;
      }

    }
    .footer{
      bottom: 0;
      font-size: 12px;
      border-top: 2px solid $color-slate;
      width: 756px;
      height: 60px;
      position: absolute;

      table{
        height: 60px;
      }

    }
    .disclosure{
      font-size: 8px;
      margin-bottom: 2px;

    }

}







/*.header table,.footer table{
  width: 100%;
}
.header table td,.footer table td{
  width: 33%;
}*/










/*.pdf-table
{
    width: 100%;
    font-size: 14px;
}

.perf-t{
  font-size: 12px;

  width: 100%;
  margin: 5px 0px 5px 0px;

  .sub-head-row th{
    font-size: 14px;
    font-weight: normal;
    padding-top: 5px;
    border-bottom: 1px solid black;
  }
  .header-row{
     .row-id{
       text-align: left;
    }

    th{
      font-size: 16px;
      font-weight: normal;
      text-align: right;

      color: #141140;
      border-bottom: 2px solid #141140;
    }
  }
  td{
    text-align: right;
    padding: 2px 0px 2px 0px;
  }
  .row-id{
    max-width: 130px;
    text-align: left;
  }
}*/