.etf-video-link
{
	border: 2px solid $color-dark-blue;
	display: inline-block;
	position: relative;

	&::before
	{
		@include transition(opacity);

		background: url(../images/play.png) no-repeat center center;
		content: ' ';
		display: block;
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
	}

	&:hover::before
	{
		opacity: 0.75;
	}

	img
	{
		display: block;
		width: 100%;
	}
}

.etf-dark-blue-section .etf-video-link
{
	border-color: $color-turquoise;
}

.video-container{
  max-width: 4490px;
  max-height: 2530px;
  margin: auto;
  overflow: hidden;
  box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.25);
  iframe{
    height: 250px;
    width: 100%;
  }
}