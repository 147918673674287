.etf-item-wrapper
{
	&.etf-item-toggle-hide-disabled .etf-item-disabled
	{
		display: none;
	}
	
	.etf-item-toggle
	{
		.etf-enabled
		{
			display: none;
		}
		
		&.etf-item-toggle-enabled
		{
			.etf-disabled
			{
				display: none;
			}
			
			.etf-enabled
			{
				display: inline-block;
			}
		}
	}
	
	.etf-item hr
	{
		position: relative;
		z-index: 2;
	}
}
