
.content-sum{

  box-shadow: 0 15px 20px 0 rgba(0,0,0,.18);

  .sum-header{
    box-shadow: 0px 1px 1px 0px rgba(0,0,0,0.25);
    padding: 15px;
  }
  .sum-body{
    padding: 15px;
  }
  .sum-footer{
    background-color: $color-dark-blue;
    padding: 15px;
    border-radius: 0px 0px 10px 10px;
  }

  border-radius: 10px;

}
.li-flex{
  display: flex;
  margin-bottom: 10px;
  i{
    margin-right: 5px;

  }
}

.content-sum-featured{
  /*box-shadow: 0px 5px 5px 4px rgba(0,196,236,0.85);*/

  box-shadow: 0 15px 20px 0 rgba(0,196,236,0.85);
  .sum-header{
    box-shadow: 0px 2px 2px 2px rgba(0,196,236,0.85);
    padding: 15px;
  }
  .sum-body{
    padding: 15px;
  }
  .sum-footer{
    background-color: $color-dark-blue;
    padding: 15px;
  }
  .l-orange{
    background-color: $color-green;
    color: $color-white;
  }
}
.models{
  .link-list{
    list-style-type: none;
    font-weight: bold;
    padding-left: 0px;

    li{
      margin-bottom: 10px;
      padding-bottom: 5px;
      border-bottom: 1px solid $color-gray;
    }
  }
  .call-box{
    background-color: $color-gray;
  }
  .sub-head{

    border-bottom: 2px solid $color-slate;
  }
}