@function strip-unit($number)
{
	@return $number / ($number * 0 + 1);
}

@function to-array($value)
{
	@if (type-of($value) == list)
	{
		@return $value;
	}
	@else
	{
		@return append((), $value);
	}
}
