#site-footer
{
	@include link-color($color-extra-light-gray);
	
	background: $color-dark-slate;
	color: $color-white;
	overflow: hidden;
	position: relative;
	z-index: 99;
	
	a:hover
	{
		text-decoration: underline;
	}
	
	footer
	{
		position: relative;
		z-index: 1;
		
		> .etf-container > .etf-row > [class^='etf-col-']
		{
			@include bp(above-tablet)
			{
				padding-left: 48px;
				padding-right: 48px;
			}
			
			@include bp(phone-to-tablet)
			{
				padding-left: 32px;
				padding-right: 32px;
			}
			
			@include bp(up-to-phone)
			{
				&:not(:first-child)
				{
					padding-top: 32px;
				}
				
				&:not(:last-child)
				{
					padding-bottom: 32px;
				}
				
				h4
				{
					@include size-and-margin(18);
				}
			}
		}
	}
}

#footer-background
{
	background: $color-slate;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 0;
}

#footer-subscribe
{
	@include bp(up-to-phone)
	{
		padding-right: 0;
	}
	
	.etf-field:not(:last-child)
	{
		margin-bottom: 0.5em;
		
		input
		{
			background: none;
			border-color: $color-white;
		}
	}
}

@include bp(up-to-phone)
{
	#footer-social-menu h4
	{
		text-align: right;
	}
}

#social-menu
{
	@include menu-reset();
	
	overflow: hidden;
	
	@include bp(above-phone)
	{
		margin: 0 0 -10px -10px;
	}
	
	@include bp(up-to-phone)
	{
		clear: right;
		float: right;
		margin: 0 0 -5px -5px;
		padding-left: 0;
	}
	
	> li
	{
		display: block;
		float: left;
		
		@include bp(above-phone)
		{
			margin: 0 0 10px 10px;
		}

		@include bp(up-to-phone)
		{
			margin: 0 0 5px 5px;
		}
		
		> a > span
		{
			@include border-radius();
		}
	}
}

#footer-tweets-wrapper
{
	position: relative;
	
	&::before,
	&::after
	{
		content: ' ';
		display: block;
		height: 10px;
		left: 0;
		position: absolute;
		width: 100%;
		z-index: 1;
	}
	
	&::before
	{
		@include vertical-gradient($color-slate, $color-slate, 1, 0);
		
		top: 0;
	}
	
	&::after
	{
		@include vertical-gradient($color-slate, $color-slate, 0, 1);
		
		bottom: 0;
	}
}

#footer-tweets
{
	font-size: 15px;
	height: 240px;
	padding: 10px 0;
	
	[class^='etf-col-']
	{
		padding-right: 32px;
	}
	
	p:not(:last-child)
	{
		margin-bottom: 0.5em;
	}
}
