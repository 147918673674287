body
{
	background: $color-white;
	color: $color-dark-slate;
	font-family: $font-body;


	@include bp(above-tablet)
	{
		font-size: $font-small-size + px;
	}
	
	@include bp(up-to-tablet)
	{
		font-size: $font-small-size + px;
	}
}

#site-loader
{
	position: fixed;
	top: 0;
	left: 0;
	z-index: 100000;
	height: 100%;
	width: 100%;
	background: $color-white;
	
	&::before
	{
		@include animation(etf-spinning, 1s, linear);
		@include border-radius();
		@include border-box();
		
		position: absolute;
		top: 50%;
		left: 50%;
		margin: -24px 0 0 -24px;
		border: 6px solid rgba($color-dark-slate, 0.25);
		border-top-color: $color-dark-slate;
		display: block;
		height: 48px;
		width: 48px;
		content: ' ';
	}
}
.etf-sidebar.sidebar-scrolled{
  padding-top: 80px;
  -webkit-transition: all 0.35s ease;
  -moz-transition: all 0.35s ease;
  -o-transition: all 0.35s ease;
  transition: all 0.35s ease;
}
.component-loader
{
	position: absolute;
	top: 0;
	left: 0;
	z-index: 100000;
	height: 100%;
	width: 100%;
	background: $color-white;

	&::before
	{
		@include animation(etf-spinning, 1s, linear);
		@include border-radius();
		@include border-box();

		position: absolute;
		top: 50%;
		left: 50%;
		margin: -24px 0 0 -24px;
		border: 6px solid rgba($color-dark-slate, 0.25);
		border-top-color: $color-dark-slate;
		display: block;
		height: 48px;
		width: 48px;
		content: ' ';
	}
}

#outer-site-wrapper
{
	overflow: hidden;
	min-width: $width-min;
}

#site-scripts
{
	position: absolute;
	right: 200%;
	bottom: 200%;
	z-index: 99999;
	height: 1px;
	width: 1px;
}
