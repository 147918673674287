.etf-slideshow
{
	min-height: 1px;
	overflow: hidden;
	position: relative;
	
	.etf-slide
	{
		position: absolute;
		top: 0;
		left: 100%;
		width: 100%;
		z-index: 0;
		
		&:first-child
		{
			left: 0;
		}
		
		&-video > a
		{
			display: block;
			
			img
			{
				width: 100%;
			}
		}
		
		&-title
		{
			background: $color-light-slate;
			color: $color-white;
			font-weight: bold;
			padding: 0.5em 2em;
			text-align: center;
		}
	}
	
	&-buttons
	{
		font-size: 0.5em;
		
		> a
		{
			bottom: 0;
			display: block;
			min-height: 1px;
			position: absolute;
			width: 4em;
			z-index: 1;

			> span
			{
				@include transition(opacity);
				@include translate-xy();
				
				bottom: 50%;
				position: absolute;
				right: 50%;
				
				> span
				{
					@include arrow($color-white);
				}
			}

			&:hover > span
			{
				opacity: 0.75;
			}

			&.etf-slide-
			{
				&previous
				{
					left: 0;

					> span > span
					{
						@include rotate(90deg);
					}
				}

				&next
				{
					right: 0;

					> span > span
					{
						@include rotate(-90deg);
					}
				}
			}
		}
	}
}

.etf-video-slideshow
{
	background: $color-light-slate;
	
	.etf-slideshow-buttons .etf-slide-
	{
		&previous
		{
			@include horizontal-gradient($color-light-slate, $color-light-slate, 1, 0, 50%);
		}
		
		&next
		{
			@include horizontal-gradient($color-light-slate, $color-light-slate, 0, 1, 0%, 50%);
		}
	}
	
}
