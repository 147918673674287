.etf-button,
button
{
	@include border-box();
	@include border-radius(0.25em)
	@include transition(background);


	background: $color-orange;
	border: 0;
	color: $color-white;
	cursor: pointer;
	display: inline-block;
	font-family: $font-body;
	font-weight: bold;
	margin: 0;
	
	@include bp(above-tablet)
	{
		padding: 0.45em 1.6em;
	}
	
	@include bp(up-to-tablet)
	{
		padding: 0.45em 0.8em;
	}

	&:hover
	{
		background: $color-dark-orange;
	}


	&[disabled]
	{
		background: $color-gray;
	}
	&.large{
	  @include bp(above-tablet){
      padding: 0.6em 1.8em;
      font-size: 1.3em;

	  }
	}
	&.green{
	  background: $color-green;
	  &:hover{
	    background: $color-dark-green;
	  }
	}
}

.etf-
{
	&categories
	{
		@include link-color(inherit, $color-turquoise);
	}
	
	&clear
	{
		clear: both;
		content: ' ';
		display: block;
		height: 0;
	}
	
	&hidden
	{
		display: none;
	}
	
	&item-disabled
	{
		position: relative;
		
		&::before
		{
			background: rgba($color-white, 0.5);
			content: ' ';
			display: block;
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%;
			z-index: 1;
		}
	}
	
	&no-overflow
	{
		overflow: hidden;
	}

	&screen-reader-text
	{
		bottom: 100%;
		position: absolute;
		right: 100%;
	}
	
	&social-link
	{
		@include transition(opacity);

		height: 2.222em;
		margin-bottom: 2px;
		margin-top: 2px;
		width: 2.222em;

		&:not(:first-child)
		{
			margin-left: 8px;
		}

		&:hover
		{
			opacity: 0.75;
		}

		> span
		{
			background: $color-linkedin;
			font-size: 0.875em;
			height: 2.222em;
			overflow: hidden;
			padding-bottom: 0;
			position: relative;
			text-indent: -99999px;
			width: 2.222em;

			&.etf-icon-facebook
			{
				background: $color-facebook;
			}

			&.etf-icon-link
			{
				background: $color-link;
			}

			&.etf-icon-twitter
			{
				background: $color-twitter;
			}

			&::before
			{
				color: $color-white;
				display: block;
				height: 2.222em;
				left: 0;
				line-height: 2.222em;
				position: absolute;
				text-align: center;
				text-indent: 0;
				top: 0;
				width: 2.222em;
				z-index: 1;
			}
		}
	}
	
	&text-
	{
		&blue
		{
			color: $color-blue;
		}

		&center
		{
			text-align: center;
		}

		&green
		{
			color: $color-green;
		}

		&right
		{
			text-align: right;
		}

		&semibold
		{
			font-weight: 600;
		}
		
		&triple-size
		{
			font-size: 3em;
		}

		&turquoise
		{
			color: $color-turquoise;
		}
	}
}
