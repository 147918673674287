#site-navigation
{
	@include link-color(inherit, $color-orange);
	
	float: right;
}

#top-menu
{
	@include bp(above-tablet-portrait)
	{
		@include menu-reset();

		float: right;
		font-family: $font-heading;
		font-size: 16px;
		margin-right: -10px;
		
		@include bp(above-pc-portrait)
		{
			padding-top: 4px;
		}

		> li
		{
			display: block;
			float: left;

			&.etf-divider
			{
				padding: 8px 0;
			}

			> a
			{
				padding: 8px 10px;
			}
			
			ul
			{
				display: none;
			}
		}
	}
	
	@include bp(up-to-tablet-portrait)
	{
		display: none;
	}
}

#main-menu
{
	@include bp(above-pc-portrait)
	{
		@include menu-reset();
		@include transition(padding);

		float: right;
		font-size: 14px;
		font-weight: 600;
 		padding-top: 14px;


		> li
		{
			display: block;
			float: left;

			> a
			{
				@include bp(above-site)
				{
					padding: 0.45em 1em;
				}
				
				@include bp(up-to-site)
				{
					padding: 0.45em 0.75em;
				}
			}
			
			&:hover > a:not(.etf-button)
			{
				color: $color-orange !important;
			}

			&:not(:first-child) > a.etf-button
			{
				margin-left: 0.45em;
			}

			&:not(:last-child) > a.etf-button
			{
				margin-right: 0.45em;
			}
			
			> ul
			{
				font-size: 0.857em;
			}
			
			ul
			{
				@include box-shadow(2px, 2px, 4px, 0, $color-shadow);
				@include link-color($color-white, $color-turquoise);
				
				background: $color-slate;
				display: none;
				left: 0;
				min-width: 100%;
				position: absolute;
				top: 100%;
				
				> li:hover > a
				{
					color: $color-turquoise !important;
				}
				
				ul
				{
					background: $color-light-slate;
					left: 100%;
					top: 0;
				}
				
				li
				{
					&:first-child > a
					{
						padding-top: 0.45em;
					}
					
					&:last-child > a
					{
						padding-bottom: 0.45em;
					}
				}
				
				a
				{
					white-space: nowrap;
					
					@include bp(above-site)
					{
						padding: 0.225em 1em;
					}

					@include bp(up-to-site)
					{
						padding: 0.225em 0.75em;
					}
				}
			}
		}
		
		li
		{
			position: relative;
		}
	}
	
	@include bp(up-to-pc-portrait)
	{
		display: none;
	}
}
.header-js-link{
  cursor: pointer;
}

body.etf-scrolled #main-menu
{
	padding-top: 8px;
}
body.etf-scrolled #main-menu.gated
{
	padding-top: 16px;
}
