

.etf-field
{
  &.etf-field-dark
  {
    input{
      background: none;
      color: $color-white;
      border: 1px solid $color-white;
    }
    input::placeholder{
      color: $color-white;
      opacity: .7;
      font-style: italic;
    }
    .etf-autocomplete-items{
      color: $color-dark-slate;
    }
  }
}